import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Images from '../../../assets/images';
import {Server, ApiPaths} from '../../../utils/Server';
import swal from 'sweetalert';
import { SortItems } from '../../../utils/DataTable';

class TaskCategories extends Component {

    constructor(props) {
        super(props);
        const {params} = props.match;
        this.state = {isLoading: false, catsData: [], searchKey: '', sortKey: 1, groupID: params.groupID, groupName: params.groupName};
    }

    componentDidMount() {
        this.handleApiCall();
    }

    handleApiCall = () => {
        this.setState({isLoading: true}, () => {
            Server.get(ApiPaths.GET_TASK_CATEGORIES)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.setState({catsData: data.data});
                    }
                })
                .catch(err => {
                    alert('Something went wrong');
                });

                this.setState({isLoading: false});
        });
    }

    handleUpdateStatus = (groupID, status) => {
      swal({
          title: "Are you sure?",
          text: "To update this group status",
          buttons: ["CANCEL", "UPDATE"],
      })
      .then((willSubmit) => {
        if (willSubmit) {
          var formData = new FormData();
          formData.append('id', groupID);
          formData.append('enabled', status);

          this.setState({isLoading: true}, () => {
            Server.post(ApiPaths.UPDATE_CATEGORY_STATUS, formData)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.handleApiCall();
                    }
                })
                .catch(err => {
                    alert('Something went wrong');
                });

                this.setState({isLoading: false});
          });
        }
      });
    }

    handleSearchAndSort = (sortKey, searchKey) => {
      this.setState({sortKey: sortKey, searchKey: searchKey});
    }

    renderTaskGroups = () => {
        const {sortKey, searchKey, groupID, groupName} = this.state;
        var catsData = this.state.catsData;

        // Sorting
        if (sortKey === 1) {
            SortItems(catsData, 'enabled', 2, 2);
        } else if (sortKey === 2) {
            SortItems(catsData, 'enabled', 2, 1);
        } else if (sortKey === 3) {
            SortItems(catsData, 'taskCat', 1, 2);
        } else if (sortKey === 4) {
            SortItems(catsData, 'taskCat', 1, 1);
        } else if (sortKey === 5) {
            SortItems(catsData, 'groupName', 1, 2);
        } else if (sortKey === 6) {
            SortItems(catsData, 'groupName', 1, 1);
        }

        // Searching
        if(searchKey !== '') {
          catsData = catsData.filter(user => 
            user.taskCat.toLowerCase().includes(searchKey) || 
            user.groupName.toLowerCase().includes(searchKey) || 
            user.maxQuestion.toString().includes(searchKey))
        }

        if(catsData && catsData.length > 0) {
          return (
            <tbody>
                {catsData.map((task, index) => (
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{task.taskCat}</td>
                        <td>{task.groupName}</td>
                        <td>{task.maxQuestion}</td>
                        <td>{task.enabled === 1 ? <button className="viewBtn bg-success" title="Active user" onClick={() => this.handleUpdateStatus(task.id, 0)}>ACTIVE </button> : <button className="viewBtn bg-danger" title="Inactive user" onClick={() => this.handleUpdateStatus(task.id, 1)}>INACTIVE </button>}</td>
                        {/* <td><Link to={'/task-groups/' + groupID + '/' + groupName + '/tasks/edit-group/' + task.id}><button className="icon_button" title={"Edit - " + task.id}> <i className="fa fa-edit text-primary"></i> </button></Link></td> */}
                    </tr>
                ))}
            </tbody>
          )
        } else {
          return (
            <tbody>
                <tr>
                    <td colSpan="12" className="text-center"> No students found.</td>
                </tr>
            </tbody>
          )
        }
    }

    render() {
        const {isLoading, sortKey, searchKey, groupID, groupName} = this.state;

        return (
          <div className="row">
              <div className="col">
              
              {/* Breadcrumbs and add button container */}
              <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <nav area-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                          <li className="breadcrumb-item"><Link to="/task-groups">Task Groups</Link> </li>
                          <li className="breadcrumb-item"><Link to={"/task-groups/" + groupID + "/" + groupName + "/tasks"}>Tasks</Link></li>
                          <li className="breadcrumb-item active" area-current="page">Categories</li>
                        </ol>
                    </nav>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <h4 className="text-center page_header">{"CATEGORIES"}</h4>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">

                  </div>
              </div>

              {/* filters, sort and search container */}
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <div className="form-group d-inline">
                    <label className="small_text">Sort by : </label>
                    <select className="form-control ml-2 w-auto d-inline" value={sortKey} onChange={(e) => this.handleSearchAndSort(parseInt(e.target.value), searchKey)}>
                        <option value="1">Active First</option>
                        <option value="2">Inactive First</option>
                        <option value="3">Category A-Z</option>
                        <option value="4">Category Z-A</option>
                        <option value="5">Group A-Z</option>
                        <option value="6">Group Z-A</option>
                    </select>
                    </div>
                </div>

                <div className="col" />

                <div className="col-md-6 col-12 text-right">
                    <div className="input-group mb-3">
                      <div className="input-group-append">
                        <button className="search_btn btn-primary px-3" type="button" onClick={this.onApplyFilters}><i className="fa fa-search"></i></button> 
                      </div>
                        <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by category, group or max questions" value={searchKey} onChange={(e) => this.handleSearchAndSort(sortKey, e.target.value.toString().toLowerCase())} autoComplete="off"/>
                    </div>
                </div>
              </div>

              {/* Display all users table */}
              <table className="table table-bordered table-striped">
                  <thead w="100%">
                      <tr>
                          <th width="5%">Sl.No.</th>
                          <th width="40%">Category</th>
                          <th width="30%">Task Group</th>
                          <th width="20%">Max Questions</th>
                          <th width="15%">Status</th>
                          {/* <th width="5%">Edit</th> */}
                      </tr>
                  </thead>

                  {/* Display all the courses */}
                  {this.renderTaskGroups()}
              </table>
              
              {/* Pagination container */}
              {/* {pagination_display} */}

              {isLoading && 
                <div className="mainLoaderContainer">
                    <img src={Images.loader} height={50} alt="Loading . . ."/>
                </div>
              }

              </div>
          </div>
        )
    }
}

export default TaskCategories;

