import React from 'react';

class InCognito extends React.Component {

    renderAnswerView = (answer, index) => {
        var answerDescription = '';
        if(answer.response1 !== '') {
            answerDescription = JSON.parse(answer.response1);
        }

        return (
            <div key={index} className="col-md-6 col-xs-12 p-2">
                <div className="card p-3">
                    <h6 className="font-weight-bold">Q {index+1}. <br/> {answer.task_description}</h6>
                    <hr className="m-2" />
                    <h6 className="font-weight-bold">Answer</h6>
                    {/* <h6 className="font-weight-bold">Time taken : {answer.time_taken ? answer.time_taken : 0} seconds</h6> */}
                    <div className="form-group p-0">
                        <textarea rows={12} className="form-control mb-1" placeholder="Description" defaultValue={answerDescription} disabled />
                        <span className="float-left leftCharacters">Characters: {answerDescription.length}</span>
                    </div>
                </div>
            </div>
        )
    }
      
    render() {
        const {taskAnswers} = this.props;

        return (
            <div className="row mb-4 pb-2">
                {taskAnswers && taskAnswers.map((answer, index) => (
                    this.renderAnswerView(answer, index)
                ))}
            </div>
        );
    }
}

export default InCognito;