export const Constants = {
    // 
};

export const TASK_ID = {
    BRAIN_DRAIN: 1,
    METRIC_1: 2,
    DIMENSION: 3,
    REFLECTION: 4,
    METRIC_2: 5,
    METRIC_3: 6,
    INCOGNITO: 7,
    MIXER: 8,
    SECRET_WORDS: 9,
    SEATING: 10,
    MASTER_MIND: 11,
    GUESS_WHO: 12,
    HANDWRITING: 13,
    TEMPO: 14,
    TWO_TUNES: 15,
    ENACT: 16,
    NAME_NARRATE: 17,
    FIXIT: 18,
    WHATS_NEXT: 19,
    PIANO: 20,
    COGNIZANT: 21,
};
