import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Admin from './components/Admin';
import AdminLogin from './components/Login/';
import { connect } from 'react-redux';

class App extends React.Component {

    render() {
        const {isAuth} = this.props;
        
        let loader = '';
        if(this.props.isLoading) {
            loader =  <div className="loader_container">
                <img src={process.env.PUBLIC_URL + '/loading_icon.gif'} height="100" className="img-fluid loader_image" alt="Loading . . ."/>
            </div>;
        } else {
            loader = '';
        }

        return (
            <div className="container-fluid" id="main_container">
            
                {/* Loading icon display if page is loading */}
                {loader}

                <Router>
                    {isAuth ?
                        <Route exact path="/" component={Admin}/>
                    :
                        <Route exact path="/" component={AdminLogin}/>
                    }
                </Router>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        isLoading: state.admin.isLoading,
        isAuth: state.admin.isAuth
    }
};

export default connect(mapStatesToProps) (App);