import {ApiPaths, Server, SetAuthToken} from '../utils/Server';

export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAILURE = 'ADMIN_LOGIN_FAILURE';
export const LOADING_TRUE = 'LOADING_TRUE';
export const LOADING_FALSE = 'LOADING_FALSE';
export const ADMIN_LOGOUT_SUCCESS = 'ADMIN_LOGOUT_SUCCESS';
export const ADMIN_LOGOUT_FAILURE = 'ADMIN_LOGOUT_FAILURE';
export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE';
export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

export const onAdminLogin = ( formData ) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return Server.post(ApiPaths.LOGIN, formData)
        .then(response => {
            dispatch(onLoadingFalse());
            console.log('Response', response.data);
            if(response.data.statusCode === 200)
            {
                const userData = response.data.data;
                dispatch(onLoginSuccess(response.data.message));
                dispatch(setCurrentUser(userData));
                const accessToken = userData.accessToken;
                localStorage.setItem('token', accessToken);
                localStorage.setItem('admin_info', JSON.stringify(userData));
                SetAuthToken(accessToken);
            }
            else
            {
                dispatch(onLoginFaiure(response.data.message));
            }
        })
        .catch(error => {
            dispatch(onLoadingFalse());
            dispatch(onLoginFaiure('Failed, Try again later'));
        });
    };
};

export const setCurrentUser = (admin_info) => {
    return {
        type : SET_CURRENT_USER,
        payload : {
            admin_info : admin_info
        }
    }
}

export const onLoginSuccess = (message) => {
    return {
        type : ADMIN_LOGIN_SUCCESS,
        payload : {
            message : message
        }
    }
}

export const onLoginFaiure = (error) => {
    return {
        type : ADMIN_LOGIN_FAILURE,
        payload : {
            error : error
        }
    }
}

export const onLoadingTrue = () => {
    return {
        type : LOADING_TRUE
    }
}

export const onLoadingFalse = () => {
    return {
        type : LOADING_FALSE
    }
}

export const onDisplayMessage = (message) => {
    return {
        type : DISPLAY_MESSAGE,
        payload : {
            message : message
        }
    }
}

export const onDisplayError = (error) => {
    return {
        type : DISPLAY_ERROR,
        payload : {
            error : error,
        }
    }
}

export const hideMessage = () => {
    return {
        type : HIDE_MESSAGE
    }
}

export const onAdminLogout = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        setTimeout(() => {
            dispatch(onLogoutSuccess('Logged out'));
            localStorage.removeItem('token');
            localStorage.removeItem('admin_info');
            SetAuthToken('');
            dispatch(onLoadingFalse());
        }, 2000);
    
        // return Server.post('/logout', null)
        // .then(response => {
            // dispatch(onLoadingFalse());
            // if(response.data.statusCode === 200)
            // {
                // dispatch(onLogoutSuccess(response.message));
                // localStorage.removeItem('token');
                // localStorage.removeItem('admin_info');
                // SetAuthToken('');
            // }
            // else
            // {
            //     dispatch(onLogoutFailure('Failed, Try again later'));
            // }
        // })
        // .catch(error => {
        //     dispatch(onLoadingFalse());
        //     dispatch(onLogoutFailure('Failed, Try again later'));
        // });
    }
}

export const updateProfile = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return Server.post(ApiPaths.UPDATE_PROFILE, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                console.log('Profile update', response.data);
                if(response.data.statusCode === 200)
                {
                    dispatch(onDisplayMessage('Profile updates'));
                    dispatch(setCurrentUser(response.data.data));
                    localStorage.setItem('admin_info', JSON.stringify(response.data.data));
                }
                else
                {
                    dispatch(onDisplayError(response.data.error));
                }
            })
            .catch(err => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, try again later'));
            })
    }
}

export const changePassword = (formData) => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return Server.post(ApiPaths.CHANGE_PASSWORD, formData)
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statusCode === 200)
                {
                    dispatch(onDisplayMessage(response.data.message));
                }
                else
                {
                    dispatch(onDisplayError(response.data.error));
                }
            })
            .catch(err => {
                dispatch(onLoadingFalse());
                dispatch(onDisplayError('Failed, try again later'));
            })
    }
}

export const onLogoutSuccess = (message) => {
    return {
        type : ADMIN_LOGOUT_SUCCESS,
        payload : {
            message : message
        }
    }
}

export const onLogoutFailure = (error) => {
    return {
        type : ADMIN_LOGOUT_FAILURE,
        payload : {
            error : error,
        }
    }
}
