import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Images from '../../assets/images';
import {Server, ApiPaths} from '../../utils/Server';
import swal from 'sweetalert';
import { SortItems } from '../../utils/DataTable';

class AllUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {isLoading: false, usersData: [], searchKey: '', sortKey: 1};
    }

    componentDidMount() {
        this.handleApiCall();
    }

    handleApiCall = () => {
        this.setState({isLoading: true}, () => {
            Server.get(ApiPaths.GET_ANSWER_USERS)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.setState({usersData: data.data});
                    }
                })
                .catch(err => {
                    alert('Something went wrong');
                });

                this.setState({isLoading: false});
        });
    }

    handleGenerateReport = (userUID, day1Status, day2Status) => {
        if (day1Status === 1 && day2Status === 1) {
            swal({
                title: "Are you sure?",
                text: "To send a report to this student",
                buttons: ["CANCEL", "SEND REPORT"],
            })
            .then((willSubmit) => {
                if (willSubmit) {
                    this.setState({isLoading: true}, () => {
                        Server.get(ApiPaths.GENERATE_REPORT + userUID)
                            .then(response => {
                                const { data } = response;
                                if(data.statusCode === 200) {
                                    swal("Success", "Report has been sent to the student", {
                                        icon: "success",
                                    });
                                } else {
                                    alert('Something went wrong');
                                }
                            })
                            .catch(err => {
                                alert('Something went wrong');
                            });

                            this.setState({isLoading: false});
                    });
                }
            });
        } else {
            swal("OOPS!...", "This student has not completed his tasks yet", {
                icon: "warning",
            });
        }
    }

    handleSearchAndSort = (sortKey, searchKey) => {
        this.setState({sortKey: sortKey, searchKey: searchKey, pageNo: 1});
    }

    renderTotalScore = (score, timer_score) => {
        var newScore = score ? score : 0;
        var newTimerScore = timer_score ? timer_score : 0;

        return <span style={{cursor: 'pointer'}} title={"Score: " + newScore + "\nTimer score: " + newTimerScore}>{(newScore + newTimerScore)}</span>;
    }

    renderUsers = () => {
        const {sortKey, searchKey} = this.state;

        var usersData = this.state.usersData;
        // var totalCount = 0;
        
        if(this.state.usersData.length > 0) {
          // Sorting
          if (sortKey === 1) {
            SortItems(usersData, 'user_id', 2, 2);
          } else if (sortKey === 2) {
            SortItems(usersData, 'user_id', 2, 1);
          } else if (sortKey === 3) {
            SortItems(usersData, 'firstName', 1, 2);
          } else if (sortKey === 4) {
            SortItems(usersData, 'firstName', 1, 1);
          }

          // Searching
          if(searchKey !== '') {
            usersData = usersData.filter(user => 
              user.firstName.toLowerCase().includes(searchKey) || 
              user.lastName.toLowerCase().includes(searchKey) || 
              user.email.toLowerCase().includes(searchKey))
            //   user.institution.toLowerCase().includes(searchKey))
          }
        }

        if(usersData && usersData.length > 0) {
          return (
            <tbody>
                {usersData.map((user, index) => (
                    <tr key={index}>
                        <td style={{verticalAlign: 'center'}}>{index+1}</td>
                        <td>{user.firstName + ' ' + user.lastName}</td>
                        <td>{user.email}</td>
                        <td>{user.institution}</td>
                        <td>{user.day1SubStatus === 1 ? 'Full' : 'Partial'}</td>
                        <td>{user.day2SubStatus === 1 ? 'Full' : 'Partial'}</td>
                        <td>{this.renderTotalScore(user.score, user.timer_score)}</td>
                        <td>{this.renderTotalScore(user.manual_score, user.manual_timer_score)}</td>
                        
                        <td><Link to={'/evaluation/students/' + user.user_id + '/' + user.firstName.trim().concat("+", user.lastName).trim() + '/tasks'}><button className="viewBtn" title="View tasks">TASKS </button></Link></td>
                        <td><button className="viewBtn" title="Send report" onClick={() => this.handleGenerateReport(user.userUid, user.day1SubStatus, user.day2SubStatus)}>SEND </button></td>
                    </tr>
                ))}
            </tbody>
          )
        } else {
            return (
                <tbody>
                    <tr>
                        <td colSpan="12" className="text-center"> No students found.</td>
                    </tr>.
                </tbody>
            )
        }
    }

    render() {
        const {isLoading, usersData, sortKey, searchKey} = this.state;

        if(isLoading) {
            return (
                // <div className="wrapper">
                    <div className="content-wrapper">
                        <div className="mainLoaderContainer">
                            <img src={Images.loader.default} height={100} alt="Loading . . ."/>
                        </div>
                    </div>
                // </div>
            )
        } else {
            return (
                <div className="row">
                    <div className="col">
                    
                    {/* Breadcrumbs and add button container */}
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                        <nav area-label="breadcrumb">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                            <li className="breadcrumb-item active" area-current="page">Students</li>
                            </ol>
                        </nav>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                        <h4 className="text-center page_header"> STUDENTS </h4>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                        {/* <Link to="/admins/add-admin"><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link> */}
                        </div>
                    </div>

                    {/* filters, sort and search container */}
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div className="form-group d-inline">
                                <label className="small_text">Sort by : </label>
                                <select className="form-control ml-2 w-auto d-inline" value={sortKey} onChange={(e) => this.handleSearchAndSort(parseInt(e.target.value), searchKey)}>
                                    <option value="1">Newest First</option>
                                    <option value="2">Oldest First</option>
                                    <option value="3">Names A-Z</option>
                                    <option value="4">Names Z-A</option>
                                </select>
                            </div>
                        </div>

                        <div className="col" />

                        <div className="col-md-6 col-12 text-right">
                            <div className="input-group mb-3">
                                <div className="input-group-append">
                                    <button className="search_btn btn-primary px-3" type="button"><i className="fa fa-search"></i></button> 
                                </div>
                                <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by name or email" value={searchKey} onChange={(e) => this.handleSearchAndSort(sortKey, e.target.value.toString().toLowerCase())} autoComplete="off"/>
                            </div>
                        </div>
                    </div>

                    {/* Display all users table */}
                    <table className="table table-bordered table-striped">
                        <thead w="100%">
                            <tr>
                                <th width="5%">Sl.No.</th>
                                <th width="35%">Student</th>
                                <th width="15%">Email ID</th>
                                <th width="15%">Institution</th>
                                <th width="12%">Day 1 Submission</th>
                                <th width="12%">Day 2 Submission</th>
                                <th width="12%">Auto Eval Score</th>
                                <th width="12%">Manual Eval Score</th>
                                <th width="10%">View</th>
                                <th width="10%">Report</th>
                            </tr>
                        </thead>

                        {/* Display all the courses */}
                        {this.renderUsers()}
                    </table>
                    
                    {/* Pagination container */}
                    {/* {pagination_display} */}

                    </div>
                </div>
            )
        }
    }
}

export default AllUsers;


// "firstName": "Deepak",
// "lastName": "Janardhan",
// "institution": "TNCES",
// "day2SubStatus": 2,
// "user_id": 82,
// "dayType2": 9,
// "day1SubStatus": 1,
// "email": "contactdeepakj@gmail.com",
// "dayType1": 15
// manual_score: 10
// manual_timer_score: 0
// score: 94
// timer_score: 2