import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Images from '../../assets/images/';
import {Server, ApiPaths} from '../../utils/Server';
import InCognito from './InCognito';
import SecretWords from './SecretWords';
import MasterMind from './MasterMind';
import HandWriting from './HandWriting';
import Enact from './Enact';
import NameNarrate from './NameNarrate';
import Fixit from './Fixit';
import Cognizant from './Cognizant';
import swal from 'sweetalert';
import {TASK_ID} from '../../assets/constants';

class UserTaskAnswers extends Component {

    constructor(props) {
        super(props);
        const {params} = props.match;
        this.state = {message: '', error: '', userID: params ? params.userID : 0, userName: params ? params.userName : '', taskName: params ? params.taskName : '', taskID: params ? params.taskID : 0, isLoading: false, taskAnswers: [], manualScore: 0, manualTimerScore: 0, comment: '',  eligibleTasks: [TASK_ID.INCOGNITO, TASK_ID.SECRET_WORDS, TASK_ID.MASTER_MIND, TASK_ID.HANDWRITING, TASK_ID.ENACT, TASK_ID.NAME_NARRATE, TASK_ID.FIXIT, TASK_ID.COGNIZANT], maxCharacters: 512, intraManualScore: 0, intraTimerScore: 0, interManualScore: 0, interTimerScore: 0};
    }

    componentDidMount() {
        this.handleApiCall();
    }

    handleApiCall = () => {
        const {userID, taskID} = this.state;

        this.setState({isLoading: true}, () => {
            Server.get(ApiPaths.GET_TASK_ANSWERS + userID + '/' + taskID)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.setState({taskAnswers: data.data});
                        // console.log('Task answers', data.data);

                        // Set scores in state
                        if (data.data.length > 0) {
                            const taskData = data.data[0];
                            var manualScore = taskData.manual_score > 0 ? taskData.manual_score : 0;
                            var manualTimerScore = taskData.manual_timer_score > 0 ? taskData.manual_timer_score : 0;
                            var comment = taskData.comment ? taskData.comment : '';

                            if (parseInt(taskID) === TASK_ID.INCOGNITO || parseInt(taskID) === TASK_ID.COGNIZANT) {
                                var answerData = comment.split('||');
                                // console.log('Commented answer', answerData);

                                if (answerData.length > 7) {
                                    var intraManualScore = parseInt(answerData[0]) + parseInt(answerData[4]);
                                    var intraTimerScore = parseInt(answerData[1]) + parseInt(answerData[5]);
                                    var interManualScore = parseInt(answerData[2]) + parseInt(answerData[6]);
                                    var interTimerScore = parseInt(answerData[3]) + parseInt(answerData[7]);

                                    this.setState({intraManualScore: intraManualScore, intraTimerScore: intraTimerScore, interManualScore: interManualScore, interTimerScore: interTimerScore});
                                }
                            }

                            this.setState({manualScore: manualScore, manualTimerScore: manualTimerScore, comment: comment});
                        }
                    }
                })
                .catch(err => {
                    alert('Something went wrong');
                });

            this.setState({isLoading: false});
        });
    }

    handleUpdateManualScore = (taskID) => {
        const {manualScore, manualTimerScore, intraManualScore, intraTimerScore, interManualScore, interTimerScore} = this.state;
        swal({
            title: "Are you sure?",
            text: "To submit these scores",
            buttons: ["CANCEL", "SUBMIT"],
        })
        .then((willSubmit) => {
            if (willSubmit) {
                this.setState({message: '', error: ''}); // Reset messages

                if (taskID === TASK_ID.INCOGNITO || taskID === TASK_ID.COGNIZANT) {
                    this.handleUpdateScores(TASK_ID.COGNIZANT, intraManualScore, intraTimerScore);
                    this.handleUpdateScores(TASK_ID.INCOGNITO, interManualScore, interTimerScore);
                } else {
                    this.handleUpdateScores(taskID, manualScore, manualTimerScore);
                }
            }
        });
        
    }

    handleUpdateScores = (taskID, manualScore, manualTimerScore) => {
        const {userID, comment} = this.state;
        var formData = new FormData();
        formData.append('userId', userID);
        formData.append('taskGroupId', taskID);
        formData.append('manualScore', manualScore);
        formData.append('manualTimerScore', manualTimerScore);
        formData.append('comment', comment);
        formData.append('adminName', 'admin');

        // Score submit api
        this.setState({isLoading: true}, async () => {
            await Server.post(ApiPaths.UPDATE_MANUAL_SCORE, formData)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.setState({message: 'Scores updated'});
                    } else {
                        this.setState({error: 'Unable to submit scores, Please try again'});
                    }
                })
                .catch(err => {
                    this.setState({error: 'Unable to submit scores, Please try again'});
                });

            this.setState({isLoading: false});
        });
    }

    handleChangeAnswer = (taskID) => {
        var comment = '';
        var intraManualScore = 0;
        var intraTimerScore = 0;
        var interManualScore = 0;
        var interTimerScore = 0;

        if (taskID === TASK_ID.HANDWRITING) {
            var comment1 = document.getElementById('comment-input-1').value;
            var comment2 = document.getElementById('comment-input-2').value;
            var comment3 = document.getElementById('comment-input-3').value;
            var comment4 = document.getElementById('comment-input-4').value;

            comment = (comment1 + '||' + comment2 + '||' + comment3 + '||' + comment4);
        } else {
            var score1 = document.getElementById('score-input-1').value;
            var score2 = document.getElementById('score-input-2').value;
            var score3 = document.getElementById('score-input-3').value;
            var score4 = document.getElementById('score-input-4').value;
            var score5 = document.getElementById('score-input-5').value;
            var score6 = document.getElementById('score-input-6').value;
            var score7 = document.getElementById('score-input-7').value;
            var score8 = document.getElementById('score-input-8').value;

            comment = (score1 + '||' + score2 + '||' + score3 + '||' + score4 + '||' + score5 + '||' + score6 + '||' + score7 + '||' + score8);

            intraManualScore = parseInt(score1) + parseInt(score5);
            intraTimerScore = parseInt(score2) + parseInt(score6);
            interManualScore = parseInt(score3) + parseInt(score7);
            interTimerScore = parseInt(score4) + parseInt(score8);
        }

        this.setState({comment: comment, intraManualScore: intraManualScore, intraTimerScore: intraTimerScore, interManualScore: interManualScore, interTimerScore: interTimerScore});

        // console.log('Commented answer', comment);
    }

    renderScoreForm = (taskID) => {
        const {manualScore, manualTimerScore, comment, maxCharacters, intraManualScore, intraTimerScore, interManualScore, interTimerScore} = this.state;
        var answerSaved = comment !== '' ? comment.split('||') : [];

        if(taskID === TASK_ID.HANDWRITING) {
            return (
                <div className="row px-3">
                    <div className="col-md-6 col-12 mb-3 px-3">
                        <div className="form-group">
                            <label className="small_text">1. Intellectual traits</label>
                            <textarea rows={6} maxLength={maxCharacters} className="form-control mb-1" id="comment-input-1" placeholder="Write your comment here..." value={answerSaved.length > 0 ? answerSaved[0] : ''} onChange={(e) => this.handleChangeAnswer(taskID)} />
                            <span className="float-left leftCharacters">Max Characters: {maxCharacters}</span>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 mb-3 px-3">
                        <div className="form-group">
                            <label className="small_text">2. Personality traits</label>
                            <textarea rows={6} maxLength={maxCharacters} className="form-control mb-1" id="comment-input-2" placeholder="Write your comment here..." value={answerSaved.length > 1 ? answerSaved[1] : ''} onChange={(e) => this.handleChangeAnswer(taskID)} />
                            <span className="float-left leftCharacters">Max Characters: {maxCharacters}</span>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 mb-3 px-3">
                        <div className="form-group">
                            <label className="small_text">3. Emotional traits</label>
                            <textarea rows={6} maxLength={maxCharacters} className="form-control mb-1" id="comment-input-3" placeholder="Write your comment here..." value={answerSaved.length > 2 ? answerSaved[2] : ''} onChange={(e) => this.handleChangeAnswer(taskID)} />
                            <span className="float-left leftCharacters">Max Characters: {maxCharacters}</span>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 mb-3 px-3">
                        <div className="form-group">
                            <label className="small_text">4. Areas that need attention/ comments</label>
                            <textarea rows={6} maxLength={maxCharacters} className="form-control mb-1" id="comment-input-4" placeholder="Write your comment here..." value={answerSaved.length > 3 ? answerSaved[3] : ''} onChange={(e) => this.handleChangeAnswer(taskID)} />
                            <span className="float-left leftCharacters">Max Characters: {maxCharacters}</span>
                        </div>
                    </div>
                </div>
            )
        } else if (taskID === TASK_ID.INCOGNITO || taskID === TASK_ID.COGNIZANT) {
            return (
                <div className="row px-3 mb-3">
                    <div className="col px-3">
                        <div className="card p-3">
                            <h6 className="text-blue">{'INCOGNITO - INTRA'}</h6>
                            <div className="row form-group">
                                <div className="col">
                                    <label className="small_text">Answers Score *</label>
                                    <input type="number" min={0} className="form-control" id="score-input-1" placeholder="Enter Answers Score" value={answerSaved.length > 0 ? answerSaved[0] : 0} onChange={(e) => this.handleChangeAnswer(taskID)} required />
                                    <span className="small_text">{'Intra answer total - ' + intraManualScore}</span>
                                </div>
                                <div className="col">
                                    <label className="small_text">Timer Score *</label>
                                    <input type="number" min={0} className="form-control" id="score-input-2" placeholder="Enter Timer Score" value={answerSaved.length > 1 ? answerSaved[1] : 0} onChange={(e) => this.handleChangeAnswer(taskID)} required />
                                    <span className="small_text">{'Intra timer total - ' + intraTimerScore}</span>
                                </div>
                            </div>
                            <hr/>

                            <h6 className="text-blue">{'INCOGNITO - INTER'}</h6>
                            <div className="row form-group">
                                <div className="col">
                                    <label className="small_text">Answers Score *</label>
                                    <input type="number" min={0} className="form-control" id="score-input-3" placeholder="Enter Answers Score" value={answerSaved.length > 2 ? answerSaved[2] : 0} onChange={(e) => this.handleChangeAnswer(taskID)} required />
                                    <span className="small_text">{'Inter answer total - ' + interManualScore}</span>
                                </div>
                                <div className="col">
                                    <label className="small_text">Timer Score *</label>
                                    <input type="number" min={0} className="form-control" id="score-input-4" placeholder="Enter Timer Score" value={answerSaved.length > 3 ? answerSaved[3] : 0} onChange={(e) => this.handleChangeAnswer(taskID)} required />
                                    <span className="small_text">{'Inter timer total - ' + interTimerScore}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col px-3">
                        <div className="card p-3">
                            <h6 className="text-blue">{'COGNIZANT - INTRA'}</h6>
                            <div className="row form-group">
                                <div className="col">
                                    <label className="small_text">Answers Score *</label>
                                    <input type="number" min={0} className="form-control" id="score-input-5" placeholder="Enter Answers Score" value={answerSaved.length > 4 ? answerSaved[4] : 0} onChange={(e) => this.handleChangeAnswer(taskID)} required />
                                    <span className="small_text">{'Intra answer total - ' + intraManualScore}</span>
                                </div>
                                <div className="col">
                                    <label className="small_text">Timer Score *</label>
                                    <input type="number" min={0} className="form-control" id="score-input-6" placeholder="Enter Timer Score" value={answerSaved.length > 5 ? answerSaved[5] : 0} onChange={(e) => this.handleChangeAnswer(taskID)} required />
                                    <span className="small_text">{'Intra timer total - ' + intraTimerScore}</span>
                                </div>
                            </div>
                            <hr/>

                            <h6 className="text-blue">{'COGNIZANT - INTER'}</h6>
                            <div className="row form-group">
                                <div className="col">
                                    <label className="small_text">Answers Score *</label>
                                    <input type="number" min={0} className="form-control" id="score-input-7" placeholder="Enter Answers Score" value={answerSaved.length > 6 ? answerSaved[6] : 0} onChange={(e) => this.handleChangeAnswer(taskID)} required />
                                    <span className="small_text">{'Inter answer total - ' + interManualScore}</span>
                                </div>
                                <div className="col">
                                    <label className="small_text">Timer Score *</label>
                                    <input type="number" min={0} className="form-control" id="score-input-8" placeholder="Enter Timer Score" value={answerSaved.length > 7 ? answerSaved[7] : 0} onChange={(e) => this.handleChangeAnswer(taskID)} required />
                                    <span className="small_text">{'Inter timer total - ' + interTimerScore}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="row px-3">
                    <div className="col px-3">
                        <div className="form-group">
                            <label className="small_text">Answers Score *</label>
                            <input type="number" min={0} className="form-control" placeholder="Enter Answers Score" value={manualScore} onChange={(e) => this.setState({manualScore: e.target.value})} required />
                        </div>
                        <div className="form-group">
                            <label className="small_text">Timer Score *</label>
                            <input type="number" min={0} className="form-control" placeholder="Enter Timer Score" value={manualTimerScore} onChange={(e) => this.setState({manualTimerScore: e.target.value})} required />
                        </div>
                    </div>
                    <div className="col px-3">
                        <div className="form-group">
                            <label className="small_text">Comment</label>
                            <textarea rows={5} className="form-control" placeholder="Write your comment here..." value={comment} onChange={(e) => this.setState({comment: e.target.value})} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderAnswersView = () => {
        const {isLoading, taskAnswers, eligibleTasks, message, error} = this.state;
        var taskID = parseInt(this.state.taskID);

        if(isLoading) {
            return (
                <div className="mainLoaderContainer">
                    <img src={Images.loader} height={50} alt="Loading . . ."/>
                </div>
            )
        } else if(!isLoading && taskAnswers && taskAnswers.length > 0 && eligibleTasks.includes(parseInt(taskID))) {

            return (
                <div>
                    {this.renderTaskView(taskID)}

                    <div className="p-2 card">
                        <h4 className="my-3 text-center">{'Submit Score'}</h4>
                        {this.renderScoreForm(taskID)}
                        
                        <button type="button" className="btn btn-primary btn-md w-25 mx-auto" onClick={() => this.handleUpdateManualScore(taskID)}>{'Submit Score'}</button>
                        {message !== "" && <h6 className="text-center mt-3 text-success">{message}</h6>}
                        {error !== "" && <h6 className="text-center mt-3 text-danger">{error}</h6>}
                    </div>
                </div>
            )
        } else if(!isLoading && taskAnswers && taskAnswers.length === 0) {
            return (
                <div className="text-center">
                    <h5 className="card-title">{'Task answers not available, Please try again'}</h5>
                    <button type="button" className="btn btn-md btn-primary mt-3" onClick={() => this.handleApiCall()}>{'TRY AGAIN'}</button>
                </div>
            )
        }
    }

    renderTaskView = (taskID) => {
        if(taskID === TASK_ID.INCOGNITO) {
            return <InCognito {...this.state} {...this.props} />
        } else if(taskID === TASK_ID.SECRET_WORDS) {
            return <SecretWords {...this.state} {...this.props} />
        } else if(taskID === TASK_ID.MASTER_MIND) {
            return <MasterMind {...this.state} {...this.props} />
        } else if(taskID === TASK_ID.HANDWRITING) {
            return <HandWriting {...this.state} {...this.props} />
        } else if(taskID === TASK_ID.ENACT) {
            return <Enact {...this.state} {...this.props} />
        } else if(taskID === TASK_ID.NAME_NARRATE) {
            return <NameNarrate {...this.state} {...this.props} maxCharacters={1000} />
        } else if(taskID === TASK_ID.FIXIT) {
            return <Fixit {...this.state} {...this.props} />
        }else if(taskID === TASK_ID.COGNIZANT) {
            return <Cognizant {...this.state} {...this.props} />
        } else {
            return null;
        }
    }

    render() {
        const {userID, userName, taskName} = this.state;

        return (
            <div className="row">
                <div className="col">
    
                    {/* Breadcrumbs and add button container */}
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <nav area-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                                    <li className="breadcrumb-item"><Link to="/evaluation/students">Students</Link> </li>
                                    <li className="breadcrumb-item"><Link to={"/evaluation/students/" + userID + '/' + userName + "/tasks"}>Tasks</Link> </li>
                                    <li className="breadcrumb-item active" area-current="page">Answers</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <h4 className="text-center page_header">{userName.replace(/[+]/g, " ")}</h4>
                            <hr className="my-2" />
                            <h5 className="text-center text-blue">{taskName.replace(/[+]/g, " ") + " - Answers"}</h5>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                        </div>
                    </div>

                    {/* filters, sort and search container */}
                    {/* <form id="search_filter_form" onSubmit={this.onApplyFilters}>
                        <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div className="form-group d-inline">
                            <label className="small_text">Sort by : </label>
                            <select name="sort_key" className="form-control ml-2 w-auto d-inline" onChange={this.onApplyFilters}>
                                <option value="1">Newest First</option>
                                <option value="2">Oldest First</option>
                                <option value="3">Users A-Z</option>
                                <option value="4">Users Z-A</option>
                            </select>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12"></div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                            <div className="input-group mb-3">
                            <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by name, email, phone" autoComplete="off"/>
                            <div className="input-group-append">
                                <button className="search_btn btn-primary px-3" type="button" onClick={this.onApplyFilters}><i className="fa fa-search"></i></button> 
                            </div>
                            </div>
                        </div>
                        </div>
                    </form> */}
                    <div className="container-fluid px-4">
                        {this.renderAnswersView()}
                    </div>
                </div>
            </div>
        )
    }
}

export default UserTaskAnswers;
