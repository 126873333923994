import React from 'react';
import './AdminUsers.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewUser } from '../../actions/Users';
import { onDisplayError } from '../../actions/Login';
import swal from 'sweetalert';
import $ from 'jquery';
import { Server, ApiPaths } from '../../utils/Server';

class AddAdmin extends React.Component {

  constructor(props){
    super(props);
    this.state = { isLoading: false };
  }

  onAddAdminSubmit = (e) => {
    e.preventDefault();

    if($('#password').val().length < 6)
    {
      this.props.onDisplayErroMessage('Password must have atleast 6 characters');
    }
    else if($('#password').val() !== $('#confirm_password').val())
    {
      this.props.onDisplayErroMessage('Confirm password must be same as password');
    }
    else
    {
      swal({
        title: "Are you sure?",
        text: "To add this new admin?",
        buttons: ['Cancel', 'Add'],
      })
      .then(willDelete => {
        if (willDelete) {
          var formData = new FormData(document.getElementById('add_user_form'));
          formData.append('status', 1);
          formData.append('enabled', 1);
          formData.append('role', 1);

          this.handleAddNewAdmin(formData);
        }
      });
    }
  }

  handleAddNewAdmin = (formData) => {
    this.setState({isLoading: true}, async () => {
        await Server.post(ApiPaths.ADD_ADMIN, formData)
          .then(response => {
              const { data } = response;
              if(data.statusCode === 200) {
                swal("Success", "Admin successfully added");
              } else {
                swal("Failed", data.message);
              }
          })
          .catch(err => {
              alert('Something went wrong');
          });

          this.setState({isLoading: false});
    });
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/admins">Admins</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Admin</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Add Admin </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">

            </div>
          </div>
          
          {/*  Add new user form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-md-4 col-12 card p-3">
              <br/>

              <form id="add_user_form" onSubmit={this.onAddAdminSubmit} autoComplete="off">
                <div className="form-group">
                  <label className="small_text"> Name : </label>
                  <input type="text" name="firstName" placeholder="First Name *" className="form-control" autoFocus required/>
                </div>

                <div className="form-group">
                  <label className="small_text"> Email ID * : </label>
                  <input type="email" name="email" placeholder="Email ID *" className="form-control" autoFocus={false} required/>
                </div>

                <div className="form-group">
                  <label className="small_text"> Mobile Number * : </label>
                  <input type="number" name="mobile" placeholder="Mobile Number *" className="form-control" required/>
                </div>

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="small_text"> New Password * : </label>
                    <input type="password" id="password" name="password" placeholder="Password" className="form-control" required/>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="small_text"> Confirm Password * : </label>
                    <input type="password" id="confirm_password" placeholder="Confirm Password" className="form-control" required/>
                  </div>
                </div>

                <div className="form-group text-center mt-4">
                  <button type="submit" className="btn btn-success" disabled={isLoading}> {isLoading ? 'Adding...' : 'Add Admin'}</button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStatesToProps = state => {
    return {
        isLoading : state.admin.isLoading,
    }
};

const mapActionsRoProps = {
    onAddNewUser : addNewUser,
    onDisplayErroMessage : onDisplayError
};

export default connect(mapStatesToProps, mapActionsRoProps) (AddAdmin);