import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Server, ApiPaths} from '../../utils/Server';
import { SortItems } from '../../utils/DataTable';
import { TASK_ID } from '../../assets/constants';

class UserTasks extends Component {
    constructor(props) {
        super(props);
        const {params} = props.match;
        this.state = {userID: params ? params.userID : 0, userName: params ? params.userName : '', isLoading: false, searchKey: '', sortKey: 1, userTasks: [], eligibleTasks: [TASK_ID.INCOGNITO, TASK_ID.SECRET_WORDS, TASK_ID.MASTER_MIND, TASK_ID.HANDWRITING, TASK_ID.ENACT, TASK_ID.NAME_NARRATE, TASK_ID.FIXIT, TASK_ID.COGNIZANT]};
    }

    componentDidMount() {
        this.handleApiCall();
    }

    handleApiCall = () => {
        const {userID} = this.state;

        this.setState({isLoading: true}, () => {
            Server.get(ApiPaths.GET_TASK_GROUPS + userID)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.setState({userTasks: data.data});
                    }
                })
                .catch(err => {
                    alert('Something went wrong');
                });

                this.setState({isLoading: false});
        });
    }

    handleSearchAndSort = (sortKey, searchKey) => {
        this.setState({sortKey: sortKey, searchKey: searchKey, pageNo: 1});
    }

    renderTotalScore = (score, timer_score) => {
        var newScore = score ? score : 0;
        var newTimerScore = timer_score ? timer_score : 0;

        return <span style={{cursor: 'pointer'}} title={"Score: " + newScore + "\nTimer score: " + newTimerScore}>{(newScore + newTimerScore)}</span>;
    }

    renderFinalScore = (score, timer_score, manual_score, manual_timer_score) => {
        var newScore = score ? score : 0;
        var newTimerScore = timer_score ? timer_score : 0;
        var newManualScore = manual_score ? manual_score : 0;
        var newManualTimerScore = manual_timer_score ? manual_timer_score : 0;

        return <span style={{cursor: 'pointer'}} title={"Auto score: " + newScore + "\nAuto timer score: " + newTimerScore + "\nManual score: " + newManualScore + "\nManual timer score: " + newManualTimerScore}>{(newScore + newTimerScore + newManualScore + newManualTimerScore)}</span>;
    }

    renderUsers = () => {
        const {userName, sortKey, searchKey, eligibleTasks} = this.state;

        var userTasks = this.state.userTasks;
        
        if(userTasks.length > 0) {
            // Sorting
            if (sortKey === 1) {
                SortItems(userTasks, 'day_type', 2, 1);
            } else if (sortKey === 2) {
                SortItems(userTasks, 'day_type', 2, 2);
            } else if (sortKey === 3) {
                SortItems(userTasks, 'group_name', 1, 2);
            } else if (sortKey === 4) {
                SortItems(userTasks, 'group_name', 1, 1);
            } else if (sortKey === 5) {
                SortItems(userTasks, 'eval_status', 2, 2);
            } else if (sortKey === 6) {
                SortItems(userTasks, 'eval_status', 2, 1);
            }

            // Searching
            if(searchKey !== '') {
                userTasks = userTasks.filter(user => 
                user.group_name.toLowerCase().includes(searchKey) ||
                user.type_name.toLowerCase().includes(searchKey) ||
                user.day_type.toString().includes(searchKey) ||
                user.task_group_id.toString().includes(searchKey))
            }
        }

        if(userTasks && userTasks.length > 0) {
            return (
                <tbody>
                    {userTasks.map((task, index) => (
                        <tr key={index}>
                            <td>{index+1}</td>
                            <td>{task.type_name}</td>
                            <td>{task.task_group_id}</td>
                            <td>{task.group_name}</td>
                            <td>{task.day_type}</td>
                            <td>{task.time_taken}</td>
                            <td>{task.auto_eval_done === 1 ? 'Yes' : 'No'}</td>
                            <td>{task.eval_status === 1 ? 'Done' : 'Pending'}</td>
                            <td>{this.renderTotalScore(task.score, task.timer_score)}</td>
                            <td>{this.renderTotalScore(task.manual_score, task.manual_timer_score)}</td>
                            <td>{this.renderFinalScore(task.score, task.timer_score, task.manual_score, task.manual_timer_score)}</td>
                            <td>{eligibleTasks.includes(parseInt(task.task_group_id)) ? <Link to={'/evaluation/students/' + task.user_id + '/' + userName + '/task-answers/' + task.task_group_id + '/' + task.group_name.replace(/[" "]/g,"+").trim()}><button className="viewBtn" title="View tasks">ANSWERS </button></Link> : ' - ' }</td>
                        </tr>
                    ))}
                </tbody>
            )
        } else {
            return (
                <tbody>
                    <tr>
                        <td colSpan="15" className="text-center"> No tasks found.</td>
                    </tr>
                </tbody>
            )
        }
    }

    render() {
        const {sortKey, searchKey, userName} = this.state;

        return (
            <div className="row">
                <div className="col">
        
                    {/* Breadcrumbs and add button container */}
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <nav area-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                                    <li className="breadcrumb-item"><Link to="/evaluation/students">Students</Link> </li>
                                    <li className="breadcrumb-item active" area-current="page">Tasks</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <h4 className="text-center page_header">{userName.replace(/[+]/g, " ")}</h4>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                            {/* <Link to="/admins/add-admin"><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link> */}
                        </div>
                    </div>

                    {/* filters, sort and search container */}
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div className="form-group d-inline">
                                <label className="small_text">Sort by : </label>
                                <select className="form-control ml-2 w-auto d-inline" value={sortKey} onChange={(e) => this.handleSearchAndSort(parseInt(e.target.value), searchKey)}>
                                    <option value="1">Day 1 First</option>
                                    <option value="2">Day 2 First</option>
                                    <option value="3">Group Names A-Z</option>
                                    <option value="4">Group Names Z-A</option>
                                    <option value="5">Eval Done First</option>
                                    <option value="6">Eval Pending First</option>
                                </select>
                            </div>
                        </div>

                        <div className="col" />

                        <div className="col-md-6 col-12 text-right">
                            <div className="input-group mb-3">
                                <div className="input-group-append">
                                    <button className="search_btn btn-primary px-3" type="button"><i className="fa fa-search"></i></button> 
                                </div>
                                <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by task name, id, day or group name" value={searchKey} onChange={(e) => this.handleSearchAndSort(sortKey, e.target.value.toString().toLowerCase())} autoComplete="off"/>
                            </div>
                        </div>
                    </div>

                    <table className="table table-responsive table-bordered table-striped">
                        <thead w="100%">
                        <tr>
                            <th width="5%">Sl.No.</th>
                            <th width="15%">Intelligence</th>
                            <th width="5%">Task ID</th>
                            <th width="20%">Task</th>
                            <th width="5%">Day</th>
                            <th width="10%">Time Taken (seconds)</th>
                            <th width="10%">Auto Eval Done</th>
                            <th width="10%">Eval Status</th>
                            <th width="10%">Auto Eval Score</th>
                            <th width="10%">Manual Eval Score</th>
                            <th width="10%">Total Score</th>
                            <th width="10%">View</th>
                        </tr>
                        </thead>

                        {/* Display all the courses */}
                        {this.renderUsers()}
                    </table>
                </div>
            </div>
        )
    }
}

export default UserTasks;

// "day_type": 1,
// "score": null,
// "user_id": 79,
// "task_group_id": 19,
// "group_name": "What's next",
// "auto_eval_done": 0,
// "meta_data": null,
// "description": "<p><b>Instructions</b></p>",
// "eval_status": 1,
// "manual_score": null,
// "time_taken": 54
