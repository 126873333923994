import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Images from '../../../assets/images';
import {Server, ApiPaths, IconsPath} from '../../../utils/Server';
import swal from 'sweetalert';
import { SortItems } from '../../../utils/DataTable';

class TaskGroups extends Component {

    constructor(props) {
        super(props);
        this.state = {isLoading: false, taskGroups: [], searchKey: '', sortKey: 1};
    }

    componentDidMount() {
        this.handleApiCall();
    }

    handleApiCall = () => {
        this.setState({isLoading: true}, () => {
            Server.get(ApiPaths.GET_ALL_TASK_GROUPS)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.setState({taskGroups: data.data});
                    }
                })
                .catch(err => {
                    alert('Something went wrong');
                });

                this.setState({isLoading: false});
        });
    }

    handleUpdateStatus = (groupID, status) => {
      swal({
          title: "Are you sure?",
          text: "To update this group status",
          buttons: ["CANCEL", "UPDATE"],
      })
      .then((willSubmit) => {
        if (willSubmit) {
          var formData = new FormData();
          formData.append('id', groupID);
          formData.append('enabled', status);

          this.setState({isLoading: true}, () => {
            Server.post(ApiPaths.UPDATE_GROUP_STATUS, formData)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.handleApiCall();
                    }
                })
                .catch(err => {
                    alert('Something went wrong');
                });

                this.setState({isLoading: false});
          });
        }
      });
    }

    handleSearchAndSort = (sortKey, searchKey) => {
      this.setState({sortKey: sortKey, searchKey: searchKey});
    }

    renderTaskGroups = () => {
        const {sortKey, searchKey} = this.state;
        var taskGroups = this.state.taskGroups;

        // Sorting
        if (sortKey === 1) {
            SortItems(taskGroups, 'enabled', 2, 2);
        } else if (sortKey === 2) {
            SortItems(taskGroups, 'enabled', 2, 1);
        } else if (sortKey === 3) {
            SortItems(taskGroups, 'groupName', 1, 2);
        } else if (sortKey === 4) {
            SortItems(taskGroups, 'groupName', 1, 1);
        } else if (sortKey === 5) {
            SortItems(taskGroups, 'dayType', 2, 1);
        } else if (sortKey === 6) {
            SortItems(taskGroups, 'dayType', 2, 2);
        }

        // Searching
        if(searchKey !== '') {
            taskGroups = taskGroups.filter(user => 
                user.groupName.toLowerCase().includes(searchKey) || 
                user.taskGroupType.toLowerCase().includes(searchKey) || 
                user.maxTaskTimer.toString().includes(searchKey) || 
                user.taskScreen.toString().includes(searchKey) || 
                user.questionMax.toString().includes(searchKey) || 
                user.dayType.toString().includes(searchKey) || 
                user.enabled.toString().includes(searchKey))
        }

        if(taskGroups && taskGroups.length > 0) {
            return (
                <tbody>
                    {taskGroups.map((group, index) => (
                        <tr key={index}>
                            <td>{index+1}</td>
                            <td><img src={IconsPath + group.icon} height={50} alt="" /></td>
                            <td>{group.taskGroupType}</td>
                            <td>{group.groupName}</td>
                            <td>{group.maxTaskTimer}</td>
                            <td>{group.taskScreen}</td>
                            <td>{group.questionMax}</td>
                            <td>{group.dayType}</td>
                            <td>{group.enabled ? <button className="viewBtn bg-success" title="Active user" onClick={() => this.handleUpdateStatus(group.id, false)}>ACTIVE </button> : <button className="viewBtn bg-danger" title="Inactive user" onClick={() => this.handleUpdateStatus(group.id, true)}>INACTIVE </button>}</td>
                            <td><Link to={'/task-groups/' + group.id + '/' + group.groupName.replace(/[ ]/g, "+") + '/tasks'}><button className="viewBtn" title="Tasks">{'TASKS'}</button></Link></td>
                            <td><Link to={'/task-groups/edit-group/' + group.id}><button className="icon_button" title={"Edit - " + group.id}> <i className="fa fa-edit text-primary"></i> </button></Link></td>
                        </tr>
                    ))}
                </tbody>
            )
        } else {
            return (
                <tbody>
                    <tr>
                        <td colSpan="12" className="text-center"> No students found.</td>
                    </tr>
                </tbody>
            )
        }
    }

    render() {
        const {isLoading, sortKey, searchKey} = this.state;

        return (
          <div className="row">
              <div className="col">
              
              {/* Breadcrumbs and add button container */}
              <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <nav area-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                          <li className="breadcrumb-item active" area-current="page">Task Groups</li>
                        </ol>
                    </nav>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <h4 className="text-center page_header"> TASK GROUPS </h4>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                    {/* <Link to="/task-groups/add-group"><button type="button" className="btn btn-primary btn-sm">ADD <i className="ml-1 fa fa-plus"></i></button></Link> */}
                  </div>
              </div>

              {/* filters, sort and search container */}
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <div className="form-group d-inline">
                    <label className="small_text">Sort by : </label>
                    <select className="form-control ml-2 w-auto d-inline" value={sortKey} onChange={(e) => this.handleSearchAndSort(parseInt(e.target.value), searchKey)}>
                        <option value="1">Active First</option>
                        <option value="2">Inactive First</option>
                        <option value="3">Names A-Z</option>
                        <option value="4">Names Z-A</option>
                        <option value="5">Day 1 First</option>
                        <option value="6">Day 2 First</option>
                    </select>
                    </div>
                </div>

                <div className="col" />

                <div className="col-md-6 col-12 text-right">
                    <div className="input-group mb-3">
                      <div className="input-group-append">
                          <button className="search_btn btn-primary px-3" type="button" onClick={this.onApplyFilters}><i className="fa fa-search"></i></button> 
                      </div>
                      <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by name, type, timer, day or questions" value={searchKey} onChange={(e) => this.handleSearchAndSort(sortKey, e.target.value.toString().toLowerCase())} autoComplete="off"/>
                    </div>
                </div>
              </div>

              {/* Display all users table */}
              <table className="table table-bordered table-striped">
                  <thead w="100%">
                      <tr>
                          <th width="5%">Sl.No.</th>
                          <th width="5%">Icon</th>
                          <th width="15%">Group Type</th>
                          <th width="25%">Group Name</th>
                          <th width="15%">Max Time</th>
                          <th width="5%">Tasks - Screen</th>
                          <th width="10%">Max Questions</th>
                          <th width="10%">Day</th>
                          <th width="10%">Active Status</th>
                          <th width="10%">Tasks</th>
                          <th width="5%">Edit</th>
                      </tr>
                  </thead>

                  {/* Display all the courses */}
                  {this.renderTaskGroups()}
              </table>
              
              {/* Pagination container */}
              {/* {pagination_display} */}

              {isLoading && 
                <div className="mainLoaderContainer">
                    <img src={Images.loader} height={50} alt="Loading . . ."/>
                </div>
              }

              </div>
          </div>
        )
    }
}

export default TaskGroups;

