import React from 'react';
import './Routes.css';
import { Route, NavLink, HashRouter as Router, Switch } from 'react-router-dom';
import $ from 'jquery';

import Dashboard from '../Dashboard';

import Users from '../Users';
import AddUser from '../Users/AddUser';
import EditUser from '../Users/EditUser';

import Admins from '../AdminUsers';
import AddAdmin from '../AdminUsers/AddAdmin';
import EditAdmin from '../AdminUsers/EditAdmin';

import AllStudents from '../Evaluation/AllUsers';
import UserTasks from '../Evaluation/UserTasks';
import UserTaskAnswers from '../Evaluation/UserTaskAnswers';

import TaskGoups from '../Tasks/TaskGroups/TaskGroups';
import AddTaskGroup from '../Tasks/TaskGroups/AddTaskGroup';
import EditTaskGroup from '../Tasks/TaskGroups/EditTaskGroup';

import Tasks from '../Tasks/Tasks/Tasks';
import EditTask from '../Tasks/Tasks/EditTask';

import TaskCategories from '../Tasks/Categories/TaskCategories.js';

import Profile from '../Profile';

class Routes extends React.Component {

	CloseMenu () {
		$('#admin_menu_navbar').collapse('hide');
	}

	render () {
		return (
			<Router>
				<nav className="navbar navbar-light" id="menu_navbar">
					<button className="navbar_btn navbar-toggler" type="button" data-toggle="collapse" data-target="#admin_menu_navbar" aria-controls="admin_menu_navbar" aria-expanded="false" aria-label="{{ __('Toggle navigation') }}">
						<img src={process.env.PUBLIC_URL + '/menu_button.png'} alt="Menu" height="30"/>
					</button>

					<div className="collapse navbar-collapse" id="admin_menu_navbar">
						<button type="button" className="close close_btn" onClick={this.CloseMenu}> <img src={process.env.PUBLIC_URL + '/close_button.png'} alt="Close" height="30"/> </button>
						<ul className="navbar-nav sticky ml-auto pt-4 pb-5">
							<li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/"> Dashboard </NavLink> </li>
							<li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/users"> Students </NavLink> </li>
							<li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/admins"> Admins </NavLink> </li>
							<li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/evaluation/students"> Evaluation </NavLink> </li>
							<li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/task-groups"> Tasks groups </NavLink> </li>
							<li> <NavLink className="nav-link" activeClassName="link_active" onClick={this.CloseMenu} exact to="/profile"> Profile </NavLink> </li>
							<br/><br/><br/>
						</ul>
					</div>
				</nav>
				<Switch>
					<Route exact path="/" component={Dashboard} />

					<Route exact path="/users" component={Users} />
					<Route exact path="/users/add-user" component={AddUser} />
					<Route exact path="/users/edit-user/:user_id" component={EditUser} />

					<Route exact path="/admins" component={Admins} />
					<Route exact path="/admins/add-admin" component={AddAdmin} />
					<Route exact path="/admins/edit-admin/:admin_id" component={EditAdmin} />

					<Route exact path="/evaluation/students" component={AllStudents} />
                    <Route exact path="/evaluation/students/:userID/:userName/tasks" component={UserTasks} />
                    <Route exact path="/evaluation/students/:userID/:userName/task-answers/:taskID/:taskName" component={UserTaskAnswers} />

					<Route exact path="/task-groups" component={TaskGoups} />
                    <Route exact path="/task-groups/add-group" component={AddTaskGroup} />
                    <Route exact path="/task-groups/edit-group/:groupID" component={EditTaskGroup} />

					<Route exact path="/task-groups/:groupID/:groupName/tasks" component={Tasks} />
                    <Route exact path="/task-groups/:groupID/:groupName/tasks/edit-task/:taskID" component={EditTask} />

					<Route exact path="/task-groups/:groupID/:groupName/task-categories" component={TaskCategories} />

					<Route exact path="/profile" component={Profile} />
				</Switch>
				<br/><br/>	
			</Router>
		);
	}
};

export default Routes;
