import React from 'react';
import './Dashboard.css';
import PieChart from 'react-simple-pie-chart';
import Images from '../../assets/images/';
import { Link } from 'react-router-dom';
import { getAdminDashboard } from '../../actions/Dashboard';
import { connect } from 'react-redux';

class Dashboard extends React.Component {

	constructor (props) {
		super(props);
		this.state = { time: new Date() };
	}

	// componentWillMount () {
	// 	if(this.props.isAuth === true)
	// 	{
	// 		setTimeout( function () { this.props.onGetAdminDashboard() }.bind(this), 1);
	// 	}
	// 	this.timerID = setInterval(() => this.changeTiming(), 1000);
	// }

	// changeTiming() {
	// 	this.setState({ time : new Date()});
	// }

	render() {

		const { users, week_users, month_users, month_callbacks, plans, consultants, callbacks } =  this.props;

		const user_graph = [
			{
				color: '#386FA4',
				value: users.paid ? users.paid : 0,
			},
			{
				color: '#88CCF1',
				value: users.unpaid ? users.unpaid : 0,
			},
			{
				color: '#EF626C',
				value: users.inactive ? users.inactive : 0,
			}
		]

		const pricing_graph = [
			{
				color: '#386FA4',
				value: plans[0] ? plans[0].total : 0,
			},
			{
				color: '#69EBD0',
				value: plans[1] ? plans[1].total : 0,
			},
			{
				color: '#88CCF1',
				value: plans[2] ? plans[2].total : 0,
			}
		]

		const consultants_graph = [
			{
				color: '#386FA4',
				value: consultants.verified ? consultants.verified : 0,
			},
			{
				color: '#88CCF1',
				value: consultants.not_verified ? consultants.not_verified : 0,
			},
			{
				color: '#EF626C',
				value: consultants.inactive ? consultants.inactive : 0,
			}
		]

		const week_users_graph = [
			{
				color: '#386FA4',
				value: week_users.paid ? week_users.paid : 0,
			},
			{
				color: '#88CCF1',
				value: week_users.unpaid ? week_users.unpaid : 0,
			},
			{
				color: '#EF626C',
				value: week_users.inactive ? week_users.inactive : 0,
			}
		]

		const month_users_graph = [
			{
				color: '#386FA4',
				value: month_users.paid ? month_users.paid : 0,
			},
			{
				color: '#88CCF1',
				value: month_users.unpaid ? month_users.unpaid : 0,
			},
			{
				color: '#EF626C',
				value: month_users.inactive ? month_users.inactive : 0,
			}
		]

		const month_callbacks_graph = [
			{
				color: '#386FA4',
				value: month_callbacks.attended ? month_callbacks.attended : 0,
			},
			{
				color: '#EF626C',
				value: month_callbacks.un_attended ? month_callbacks.un_attended : 0,
			}
		]

		let users_display = [];
		let payments_display = [];
		let callbacks_display = [];

		if(users && users.recent_users && users.recent_users.length > 0)
		{
			users_display = <div className="text-left">
					{
						users.recent_users && users.recent_users.map((user, index) => (
							<div key={index}><span className="small_text text-blue"> {index+1 + ') ' + user.firstName + ' ' + user.lname + ' - ' + user.mobile + ' - (' + user.created_at.substring(0,10) + ')'}</span><br/></div>
						))
					}
					<div className="text-center mt-2"><Link to="/users"><button className="btn btn-info btn-sm ml-0"><b> View all </b></button></Link></div>
				</div>
		}
		else
		{
			users_display = <div><br/><span className="small_text text-blue"> No users</span><br/></div>
		}

		if(users && users.recent_paid && users.recent_paid.length > 0)
		{
			payments_display = <div className="text-left">
					{
						users.recent_paid && users.recent_paid.map((user, index) => (
							<div key={index}><span className="small_text text-blue"> {index+1 + ') ' + user.firstName + ' ' + user.lname + ' - ' + user.mobile + ' - (' + user.paid_at.substring(0,10) + ')'}</span><br/></div>
						))
					}
					<div className="text-center mt-2"><Link to="/users"><button className="btn btn-info btn-sm ml-0"><b> View all </b></button></Link></div>
				</div>
		}
		else
		{
			payments_display = <div><br/><span className="small_text text-blue"> No payments</span><br/></div>
		}

		if(callbacks && callbacks.length > 0)
		{
			callbacks_display = <div className="text-left">
					{
						callbacks && callbacks.map((call, index) => (
							<div key={index}><span className="small_text text-blue"> {index+1 + ') ' + call.firstName + ' - ' + call.mobile + ' - (' + call.created_at.substring(0,10) + ')'}</span><br/></div>
						))
					}
					<div className="text-center mt-2"><Link to="/callbacks"><button className="btn btn-info btn-sm ml-0"><b> View all </b></button></Link></div>
				</div>
		}
		else
		{
			callbacks_display = <div><br/><span className="small_text text-blue"> No callbacks</span><br/></div>
		}

		return (
			<div className="row">
				<div className="col">
				
					{/* Breadcrumbs and add button container */}
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
							<nav area-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item active" area-current="page">Dashboard</li>
								</ol>
							</nav>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
							<h4 className="text-center page_header"> Dashboard </h4>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
							{/* <h2 className="text-orange"> {this.state.time.toLocaleTimeString()} </h2> */}
						</div>
					</div>

					<div className="row">
						<div className="col" />
						<div className="col text-center pt-5">
							<br/><br/>
							<h1 style={{fontSize: 40}}>Welcome to</h1>
							<img src={Images.logo} height={200} alt="" />
						</div>
						<div className="col" />
					</div>
				</div>
			</div>
		);
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
	}
}

const mapStatesToProps = (state) => {
	return {
		isAuth: state.admin.isAuth,
		users: state.dashboard.users,
		week_users: state.dashboard.week_users,
		month_users: state.dashboard.month_users,
		month_callbacks: state.dashboard.month_callbacks,
		plans: state.dashboard.plans,
		consultants: state.dashboard.consultants,
		callbacks: state.dashboard.callbacks
	}
}

const mapActionsToProps = {
	onGetAdminDashboard : getAdminDashboard
}

export default connect(mapStatesToProps, mapActionsToProps) (Dashboard);