import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Images from '../../../assets/images';
import {Server, ApiPaths} from '../../../utils/Server';
import swal from 'sweetalert';
import { SortItems } from '../../../utils/DataTable';

class Tasks extends Component {

    constructor(props) {
        super(props);
        const {params} = props.match;
        this.state = {isLoading: false, tasksData: [], searchKey: '', sortKey: 1, groupID: params.groupID, groupName: params.groupName};
    }

    componentDidMount() {
        this.handleApiCall();
    }

    handleApiCall = () => {
        const {groupID} = this.state;
        this.setState({isLoading: true}, () => {
            Server.get(ApiPaths.GET_GROUP_TASKS + groupID)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.setState({tasksData: data.data});
                    }
                })
                .catch(err => {
                    alert('Something went wrong');
                });

                this.setState({isLoading: false});
        });
    }

    handleUpdateStatus = (groupID, status) => {
      swal({
          title: "Are you sure?",
          text: "To update this group status",
          buttons: ["CANCEL", "UPDATE"],
      })
      .then((willSubmit) => {
        if (willSubmit) {
          var formData = new FormData();
          formData.append('id', groupID);
          formData.append('enabled', status);

          this.setState({isLoading: true}, () => {
            Server.post(ApiPaths.UPDATE_TASK_STATUS, formData)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.handleApiCall();
                    }
                })
                .catch(err => {
                    alert('Something went wrong');
                });

                this.setState({isLoading: false});
          });
        }
      });
    }

    handleSearchAndSort = (sortKey, searchKey) => {
      this.setState({sortKey: sortKey, searchKey: searchKey});
    }

    renderTaskGroups = () => {
        const {sortKey, searchKey, groupID, groupName} = this.state;
        var tasksData = this.state.tasksData;

        // Sorting
        if (sortKey === 1) {
            SortItems(tasksData, 'enabled', 2, 2);
        } else if (sortKey === 2) {
            SortItems(tasksData, 'enabled', 2, 1);
        } else if (sortKey === 3) {
            SortItems(tasksData, 'taskDesc', 1, 2);
        } else if (sortKey === 4) {
            SortItems(tasksData, 'taskDesc', 1, 1);
        } else if (sortKey === 5) {
            SortItems(tasksData, 'task_weight', 2, 2);
        } else if (sortKey === 6) {
            SortItems(tasksData, 'catId', 2, 2);
        }

        // Searching
        if(searchKey !== '') {
          tasksData = tasksData.filter(user => 
            user.taskDesc.toLowerCase().includes(searchKey) || 
            user.categoryName.toLowerCase().includes(searchKey) || 
            user.catId.toString().includes(searchKey) || 
            user.taskType.toString().includes(searchKey) || 
            user.task_weight.toString().includes(searchKey))
        }

        if(tasksData && tasksData.length > 0) {
          return (
            <tbody>
                {tasksData.map((task, index) => (
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{task.taskDesc && task.taskDesc !== '' ? task.taskDesc.substr(0, 300) : '- - -'}</td>
                        <td>{task.categoryName}</td>
                        <td>{task.taskType}</td>
                        <td>{task.task_weight}</td>
                        <td>{task.enabled ? <button className="viewBtn bg-success" title="Active user" onClick={() => this.handleUpdateStatus(task.id, false)}>ACTIVE </button> : <button className="viewBtn bg-danger" title="Inactive user" onClick={() => this.handleUpdateStatus(task.id, true)}>INACTIVE </button>}</td>
                        <td><Link to={'/task-groups/' + groupID + '/' + groupName + '/tasks/edit-group/' + task.id}><button className="icon_button" title={"Edit - " + task.id}> <i className="fa fa-edit text-primary"></i> </button></Link></td>
                    </tr>
                ))}
            </tbody>
          )
        } else {
          return (
            <tbody>
                <tr>
                    <td colSpan="12" className="text-center"> No students found.</td>
                </tr>
            </tbody>
          )
        }
    }

    render() {
        const {isLoading, sortKey, searchKey, groupID, groupName} = this.state;

        return (
          <div className="row">
              <div className="col">
              
              {/* Breadcrumbs and add button container */}
              <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <nav area-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                          <li className="breadcrumb-item"><Link to="/task-groups">Task Groups</Link> </li>
                          <li className="breadcrumb-item active" area-current="page">Tasks</li>
                        </ol>
                    </nav>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <h4 className="text-center page_header">{groupName.replace(/[+]/g, " ")}</h4>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                    <Link to={"/task-groups/" + groupID + "/" + groupName + "/task-categories"}><button type="button" className="btn btn-primary btn-sm">CATEGORIES <i className="ml-1 fa fa-eye"></i></button></Link>
                  </div>
              </div>

              {/* filters, sort and search container */}
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <div className="form-group d-inline">
                    <label className="small_text">Sort by : </label>
                    <select className="form-control ml-2 w-auto d-inline" value={sortKey} onChange={(e) => this.handleSearchAndSort(parseInt(e.target.value), searchKey)}>
                        <option value="1">Active First</option>
                        <option value="2">Inactive First</option>
                        <option value="3">Names A-Z</option>
                        <option value="4">Names Z-A</option>
                        <option value="5">Weight H-L</option>
                        <option value="6">Category H-L</option>
                    </select>
                    </div>
                </div>

                <div className="col" />

                <div className="col-md-6 col-12 text-right">
                    <div className="input-group mb-3">
                      <div className="input-group-append">
                          <button className="search_btn btn-primary px-3" type="button" onClick={this.onApplyFilters}><i className="fa fa-search"></i></button> 
                      </div>
                      <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by description, weight, type or catgeory" value={searchKey} onChange={(e) => this.handleSearchAndSort(sortKey, e.target.value.toString().toLowerCase())} autoComplete="off"/>
                    </div>
                </div>
              </div>

              {/* Display all users table */}
              <table className="table table-bordered table-striped">
                  <thead w="100%">
                      <tr>
                          <th width="5%">Sl.No.</th>
                          <th width="50%">Description</th>
                          <th width="10%">Category</th>
                          <th width="10%">Task Type</th>
                          <th width="10%">Weight</th>
                          <th width="10%">Active Status</th>
                          <th width="5%">Edit</th>
                      </tr>
                  </thead>

                  {/* Display all the courses */}
                  {this.renderTaskGroups()}
              </table>
              
              {/* Pagination container */}
              {/* {pagination_display} */}

              {isLoading && 
                <div className="mainLoaderContainer">
                    <img src={Images.loader} height={50} alt="Loading . . ."/>
                </div>
              }

              </div>
          </div>
        )
    }
}

export default Tasks;

