import {Server} from '../utils/Server';
import { onLoadingTrue, onLoadingFalse } from './Login';

export const GET_DASHBOARD = 'GET_DASHBOARD';

export const getAdminDashboard = () => {
    return (dispatch) => {
        dispatch(onLoadingTrue());
        return Server.get('/get_admin_dashboard')
            .then(response => {
                dispatch(onLoadingFalse());
                if(response.data.statusCode === 200)
                {
                    dispatch(onGetDashboardSuccess(response.data.dashboard));
                }
            })
            .catch(err => {
                dispatch(onLoadingFalse());
            });
    };
}

export const onGetDashboardSuccess = (data) => {
    return {
        type : GET_DASHBOARD,
        payload : {
            users : data.users,
            week_users : data.week_users,
            month_users : data.month_users,
            month_callbacks : data.month_callbacks,
            plans : data.plans,
            consultants : data.consultants,
            callbacks : data.callbacks
        }
    }
}