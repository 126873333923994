import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import Admin_login_reducer from '../reducers/Login';
import Admin_dashboard_reducer from '../reducers/Dashboard';
import Pagination_reducer from '../reducers/Pagination';
import User_reducer from '../reducers/Users';

const All_reducers = combineReducers({
    admin: Admin_login_reducer,
    dashboard: Admin_dashboard_reducer,
    paginate: Pagination_reducer,
    user: User_reducer,
});
  
const store = createStore(All_reducers, applyMiddleware(thunk));

export default store;
