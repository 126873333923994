import React from 'react';

class MasterMind extends React.Component {

    renderAnswerView = (answer, index) => {
        var answerData = '';
        var questionNumbers = [];

        if(answer.task_description !== '') {
            questionNumbers = answer.task_description.replace(/[|]/g,'');
        }

        if(answer.response1 !== '') {
            answerData = JSON.parse(answer.response1);
        }

        return (
            <div key={index} className="col-md-6 col-xs-12 p-2">
                <div className="card p-3">
                    <h6 className="font-weight-bold mb-1">Q {index+1}.</h6>
                    <h5 className="font-weight-bold mb-1">{'Numbers : ' + questionNumbers}</h5>
                    <h6 className="font-weight-bold mb-1">Time taken : {answer.time_taken ? answer.time_taken : 0} seconds</h6>
                    <h6 className="font-weight-bold mb-1">Attempts : {answerData.length}</h6>
                    <div className="row my-2 text-center">
                        <div className="col">
                            {(answerData && answerData.length > 0) && (
                                <table className="table table-bordered text-center">
                                    <thead className="bg-blue text-light">
                                        <tr>
                                            <th width="10%">Attempt</th>
                                            <th width="30%">Guessed number</th>
                                            <th width="30%">Right numbers in right place</th>
                                            <th width="30%">Right numbers in wrong place</th>
                                            <th width="10%">Result</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {answerData.map((answer, index) => (
                                            <tr key={index}>
                                                <td>{(answerData.length) - (index)}</td>
                                                <td>{answer.guessedNumber}</td>
                                                <td>{answer.rightPlace}</td>
                                                <td>{answer.wrongPlace}</td>
                                                <td><i className={answer.rightPlace === 4 ? "fa fa-check text-success" : "fa fa-times text-danger"}></i></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
      
    render() {
        const {taskAnswers} = this.props;

        return (
            <div className="row mb-4 pb-2">
                {taskAnswers && taskAnswers.map((answer, index) => (
                    this.renderAnswerView(answer, index)
                ))}
            </div>
        );
    }
}

export default MasterMind;