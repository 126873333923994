import React from 'react';

class Cognizant extends React.Component {
    
    renderAnswerView = (answer, index) => {
        var answerResponse = JSON.parse(answer.response1);
        var answerTitle = '';
        var answerDescription1 = '';
        var answerDescription2 = '';
        var answerDescription3 = '';

        if(answer.response1 !== '') {
            var answers = answerResponse.split('||');
            
            // Set answers
            answerTitle = answers.length > 0 ? answers[0] : '';
            answerDescription1 = answers.length > 1 ? answers[1] : '';
            answerDescription2 = answers.length > 2 ? answers[2] : '';
            answerDescription3 = answers.length > 3 ? answers[3] : '';
        }

        return (
            <div key={index} className="card mt-3">
                <div className="card-body row">
                    <div className="col p-4 text-center">
                        <h6 className="font-weight-bold">Question {index+1}</h6>
                        <img src={answer.task_description} className="img-fluid" alt="" />
                    </div>
                    <div className="col answerCard text-center">
                        <h6 className="font-weight-bold">Answer</h6>
                        {/* <h6 className="font-weight-bold">Time taken : {answer.time_taken ? answer.time_taken : 0} seconds</h6> */}
                        <div className="col form-group">
                            <h6 className="text-left small_text">Title:</h6>
                            <input type="text" className="form-control" placeholder="Title" defaultValue={answerTitle} disabled />
                            <br/>
                            
                            <h6 className="text-left small_text">{answer.task_option1}</h6>
                            <textarea rows={4} className="form-control mb-1" placeholder="Description" defaultValue={answerDescription1} disabled />
                            <span className="float-left leftCharacters">Characters: {answerDescription1.length}</span>
                            <br/>

                            <h6 className="text-left small_text mt-2">{answer.task_option2}</h6>
                            <textarea rows={4} className="form-control mb-1" placeholder="Description" defaultValue={answerDescription2} disabled />
                            <span className="float-left leftCharacters">Characters: {answerDescription2.length}</span>
                            <br/>

                            <h6 className="text-left small_text mt-2">{answer.task_option3}</h6>
                            <textarea rows={4} className="form-control mb-1" placeholder="Description" defaultValue={answerDescription3} disabled />
                            <span className="float-left leftCharacters">Characters: {answerDescription3.length}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
      
    render() {
        const {taskAnswers} = this.props;

        return (
            <div className="mt-2 mb-4 pb-2">
                {taskAnswers && taskAnswers.map((answer, index) => (
                    this.renderAnswerView(answer, index)
                ))}
            </div>
        );
    }
}

export default Cognizant;