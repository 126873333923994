const Images = {
    avatar: require('./Avatar.png'),
    dimensionSample1: require('./dimensionSample1.png'),
    dimensionSample2: require('./dimensionSample2.png'),
    dimensionSample3: require('./dimensionSample3.png'),
    dimensionStandard1: require('./dimensionStandard1.png'),
    dimensionStandard2: require('./dimensionStandard2.png'),
    dimensionStandard3: require('./dimensionStandard3.png'),
    dimensionStandard4: require('./dimensionStandard4.png'),
    logo: require('./logo.png'),
    loader: require('./loader.gif'),
    day: require('./day.png'),
    choose: require('./choose.png'),
    table: require('./table.png'),
    deleteWhite: require('./deleteWhite.png'),
    deleteBlack: require('./deleteBlack.png'),
    fixitSVG: require('./fixit/fixit-10.svg'),
    fixit1: require('./fixit/fixit-1.png'),
    fixit2: require('./fixit/fixit-2.png'),
    fixit3: require('./fixit/fixit-3.png'),
    fixit4: require('./fixit/fixit-4.png'),
    fixit5: require('./fixit/fixit-5.png'),
    fixit6: require('./fixit/fixit-6.png'),
    fixit7: require('./fixit/fixit-7.png'),
    fixit8: require('./fixit/fixit-8.png'),
    fixit9: require('./fixit/fixit-9.png'),
};

export default Images;
