import React from 'react';
import './AdminUsers.css';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import $ from 'jquery';
import {Server, ApiPaths} from '../../utils/Server';

class EditAdmin extends React.Component {
  constructor(props){
    super(props);
    this.state = {isLoading: false, error: '', adminData: {}, adminID : this.props.match.params.admin_id };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentWillMount () {
    this.handleGetAdmins(this.state.adminID);
  }

  handleGetAdmins = (adminID) => {
    this.setState({isLoading: true}, async () => {
        await Server.get(ApiPaths.GET_ALL_ADMINS)
          .then(response => {
              const { data } = response;
              if(data.statusCode === 200) {
                var adminData = data.data.filter(admin => parseInt(admin.id) === parseInt(adminID));
                this.setState({adminData: adminData[0]});
              } else {
                alert('Something went wrong', response.data.message);
              }
          })
          .catch(err => {
              alert('Something went wrong');
          });

          this.setState({isLoading: false});
    });
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    let adminData = this.state.adminData;
    adminData[name] = value;
    this.setState({ adminData : adminData});
  }

  onUpdateUserSubmit = (e) => {
    e.preventDefault();
    if(($('#password').val() !== '' || $('#confirm_password').val() !== '') || ($('#password').val() !== $('#confirm_password').val())) {
      if($('#password').val().length < 6) {
        this.setState({error: 'Password must have atleast 6 characters'});
      } else if($('#password').val() !== $('#confirm_password').val()) {
        this.setState({error: 'Confirm password must be same as password'});
      } else {
        this.setState({error: ''}, () => {
          this.handleUpdateAdmin();
        });
      }
    } else {
      this.setState({error: ''}, () => {
        this.handleUpdateAdmin();
      });
    }
  }

  handleUpdateAdmin = () => {
    swal({
      title: "Are you sure?",
      text: "To update this admin?",
      buttons: ['Cancel', 'Update'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_user_form'));
        formData.append('id', this.state.adminID);
        formData.append('status', 1);
        formData.append('enabled', 1);
        formData.append('role', 1);

        this.setState({isLoading: true}, async () => {
            await Server.post(ApiPaths.UPDATE_PROFILE, formData)
              .then(response => {
                  const { data } = response;
                  if(data.statusCode === 200) {
                    swal("Success", "Admin successfully updates");
                  } else {
                    swal("Failed", data.message);
                  }
              })
              .catch(err => {
                  alert('Something went wrong');
              });

              this.setState({isLoading: false});
        });
      }
    });
  }

  render() {

    const { isLoading, adminData, error } = this.state;
  
    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/admins">Admins</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Admin</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> Edit Admin </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/admins/add-admin"><button type="button" className="btn btn-primary btn-sm">Add <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
    
          {/*  Edit user form  */}
          <div className="row edit_container form_container">
            <div className="col"></div>
            <div className="col-md-4 col-12 card p-3">
              <br/>

              <form id="edit_user_form" onSubmit={this.onUpdateUserSubmit} autoComplete="off">

                <div className="form-group">
                  <label className="small_text"> Full Name : </label>
                  <input type="text" name="firstName" placeholder="Full Name *" value={adminData ? adminData.firstName : ''} onChange={this.onChangeHandler} className="form-control" autoFocus required/>
                </div>

                <div className="form-group">
                  <label className="small_text"> Email ID * : </label>
                  <input type="email" name="email" placeholder="Email ID *" value={adminData ? adminData.email : ''} onChange={this.onChangeHandler} className="form-control" required/>
                </div>

                <div className="form-group">
                  <label className="small_text"> Mobile Number * : </label>
                  <input type="number" name="mobile" placeholder="Mobile Number *" value={adminData ? adminData.mobile : ''} onChange={this.onChangeHandler} className="form-control" required/>
                </div>

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="small_text"> New Password * : </label>
                    <input type="password" id="password" name="password" placeholder="Password" className="form-control"/>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="small_text"> Confirm Password * : </label>
                    <input type="password" id="confirm_password" placeholder="Confirm Password" className="form-control"/>
                  </div>
                </div>

                {(error && error !== '') && <p className="small_text text-danger text-center mt-3">{error}</p>}

                <div className="form-group text-center mt-4">
                  <button type="submit" className="btn btn-success" disabled={this.state.isLoading}>{isLoading ? 'Updating...' : 'Update Admin'}</button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditAdmin;