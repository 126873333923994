import axios from 'axios';

export const BaseURL = 'https://api.jagrati.reduxcel.com/';

export const IconsPath = 'https://reduxcel-public.s3.ap-south-1.amazonaws.com/task_objects/task_group_icons/';

export const Server = axios.create({
    baseURL : BaseURL,
    headers : {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Partner': 'Jagrati',
        'Source': 'Admin'
    },
});

export const SetAuthToken = (token) => {
    if(token) {
        Server.defaults.headers.common.Token = token;
    } else {
        delete Server.defaults.headers.common.Token;
    }
};

export const ApiPaths = {
    LOGIN: 'admin/login',
    UPDATE_PROFILE: 'admin/update',
    CHANGE_PASSWORD: 'admin/changepassword',
    GET_ALL_ADMINS: 'admin/getAll',
    ADD_ADMIN: 'admin/add',
    UPDATE_ADMIN_STATUS: 'admin/updateEnabledFlag',
    GET_STUDENTS: 'user/getAllUsers',
    ADD_STUDENT: 'user/addFromAdmin',
    UPDATE_STUDENT: 'user/updateUserDetails',
    UPDATE_STUDENT_STATUS: 'user/updateEnabledFlag',
    GET_ALL_TASK_GROUPS: 'task/taskGroup/all',
    EDIT_TASK_GROUP: 'task/taskGroup/edit/',
    UPDATE_GROUP_STATUS: 'task/taskGroup/updateEnableFlag',
    GET_TASK_GROUP_TYPES: 'task/getTaskGroupTypes',
    UPDATE_TASK_GROUP: 'task/taskGroup/update',
    GET_GROUP_TASKS: 'task/getTaskByGroup/',
    UPDATE_TASK_STATUS: 'task/updateTaskEnabledFlag',
    GET_ANSWER_USERS: 'admin/getAnsSubmittedUsers',
    GET_TASK_GROUPS: 'admin/getSubmittedTaskGroups/',
    GET_TASK_CATEGORIES: 'task/taskCategory/all',
    UPDATE_CATEGORY_STATUS: 'task/taskCategory/updateEnableFlag',
    GET_TASK_ANSWERS: 'admin/getSubmittedTask/',
    UPDATE_MANUAL_SCORE: 'admin/updateManualScore',
    GENERATE_REPORT: 'user/generateReport/',
};
