import React from 'react';
import './Users.css';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import $ from 'jquery';
import {Server, ApiPaths} from '../../utils/Server';
import moment from 'moment';

class AddUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {isLoading: false, error: '', dob: '', age: 0};
  }

  onAddUserSubmit = (e) => {
    e.preventDefault();

    // if($('#password').val().length < 6)
    // {
    //   this.setState({error: 'Password must have atleast 6 characters'});
    // }
    // else if($('#password').val() !== $('#password_confirm').val())
    // {
    //   this.setState({error: 'Confirm password must be same as password'});
    // }
    // else
    // {
      this.setState({error: ''});
      swal({
        title: "Are you sure?",
        text: "To add this new user?",
        buttons: ['CANCEL', 'ADD'],
      })
      .then(willDelete => {
        if (willDelete) {
          var formData = new FormData(document.getElementById('add_user_form'));
          formData.append('createdBy', 'admin');
          formData.append('enabled', 1);
          formData.append('status', 1);
          this.handleAddUser(formData);
        }
      });
    // }
  }

  handleAddUser = (formData) => {
    this.setState({isLoading: true}, async () => {
        await Server.post(ApiPaths.ADD_STUDENT, formData)
          .then(response => {
              const { data } = response;
              if(data.statusCode === 200) {
                swal("Success", "Student added", {icon: 'success'});
              } else {
                this.setState({error: response.data.message});
              }
          })
          .catch(err => {
            this.setState({error: err.message});
          });

          this.setState({isLoading: false});
    });
  }

  handleDateChange = (date) => {
    if (date !== ' ' && date !== undefined) {
        var today = moment(new Date());
        var birthDate = moment(new Date(date));

        var diffYears = today.diff(birthDate, 'years');

        this.setState({age: diffYears});
    }

    this.setState({dob: date});
}

  render() {
    const {isLoading, error, dob, age} = this.state;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/users">Students</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Add Student</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> ADD STUDENT </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">

            </div>
          </div>
          
          {/*  Add new user form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br/>

              <form id="add_user_form" onSubmit={this.onAddUserSubmit} autoComplete="off">

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text">First Name *</label>
                        <input type="text" name="firstName" placeholder="First Name *" className="form-control" required autoFocus/>
                      </div>

                      <div className="col">
                        <label className="small_text">Last Name *</label>
                        <input type="text" name="lastName" placeholder="Last Name *" className="form-control" required/>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Email ID *</label>
                      <input type="email" name="email" placeholder="Email ID *" className="form-control" required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Mobile Number *</label>
                      <input type="number" minLength={10} maxLength={10} name="mobile" placeholder="Mobile Number *" className="form-control" required/>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text">Date of birth</label>
                          <input type="date" name="dob" className="form-control" placeholder="Date of birth" min={'1995-01-01'} max={'2016-12-31'} value={dob} onChange={(e) => this.handleDateChange(e.target.value)} required />
                      </div>
                      <div className="col">
                        <label className="small_text">Age in years</label>
                          <input type="text" disabled className="form-control" value={age && age !== '' ? (age + ' years')  : '0'} />
                          <input type="hidden" name="age" value={age} />
                      </div>
                    </div>

                    <div className="form-group">
                        <label className="small_text">Institution *</label>
                        <input type="text" name="institution" className="form-control" placeholder="Institution" required />
                    </div>

                    <div className="form-group">
                        <label className="small_text">Class *</label>
                        <input type="text" name="clazz" className="form-control" placeholder="Class" required />
                    </div>
                
                    <div className="form-group">
                        <label className="small_text">Father Name *</label>
                        <input type="text" name="fatherName" className="form-control" placeholder="Father Name" required />
                    </div>
                
                    <div className="form-group">
                        <label className="small_text">Father Occupation *</label>
                        <input type="text" name="fatherOccupation" className="form-control" placeholder="Father Occupation" required />
                    </div>
                
                    <div className="form-group">
                        <label className="small_text">Mother Name *</label>
                        <input type="text" name="motherName" className="form-control" placeholder="Mother Name" required />
                    </div>
                
                    <div className="form-group">
                        <label className="small_text">Mother Occupation *</label>
                        <input type="text" name="motherOccupation" className="form-control" placeholder="Mother Occupation" required />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                        <label className="small_text">Address 1</label>
                        <textarea rows={6} name="address1" className="form-control" placeholder="Address 1"></textarea>
                    </div>
                
                    <div className="form-group">
                        <label className="small_text">Address 2</label>
                        <textarea rows={6} name="address2" className="form-control" placeholder="Address 2"></textarea>
                    </div>
                
                    <div className="form-group">
                        <label className="small_text">Address 3</label>
                        <textarea rows={5} name="address3" className="form-control" placeholder="Address 3"></textarea>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text">City</label>
                        <input type="text" name="city" className="form-control" placeholder="City" />
                      </div>

                      <div className="col">
                        <label className="small_text">Pin Code</label>
                        <input type="text" name="pinCode" className="form-control" placeholder="Pin Code" />
                      </div>
                    </div>
                
                    <div className="form-group">
                        <label className="small_text">Secondary Email ID</label>
                        <input type="email" name="secEmail" className="form-control" placeholder="Secondary Email ID" />
                    </div>
                    
                    <div className="form-group">
                        <label className="small_text">Secondary Mobile</label>
                        <input type="number" name="secMobile" className="form-control" placeholder="Secondary Mobile" />
                    </div>
                  </div>
                </div>

                {(error && error !== '') && <p className="small_text text-danger">{error}</p>}

                <div className="form-group text-center mt-4">
                  <button type="submit" className="btn btn-success" disabled={isLoading}>{isLoading ? 'Adding...' : 'Add User'}</button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

export default (AddUser);