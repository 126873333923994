import React from 'react';
import Pagination from 'react-js-pagination';

const PaginationScreen = (props) => {
    return (
        <div className="row pt-1">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
                <div className="form-group d-inline">
                    <label className="small_text">Page size : </label>
                    <select name="page_size_changer" className="form-control ml-2 w-auto d-inline" value={props.pageSize} onChange={(e) => props.handlePageChange(props.activePage, e.target.value)}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <p className="small_text d-inline ml-2"> Showing {props.currentCount} of {props.totalCount} records. </p>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
                {/* {props.totalCount > props.pageSize &&  */}
                    <div className="pagination_container table-responsive">
                        <Pagination
                            activePage={props.activePage}
                            itemsCountPerPage={props.pageSize}
                            totalItemsCount={props.totalCount}
                            pageRangeDisplayed={props.pageRange}
                            onChange={(page) => {
                                props.handlePageChange(page, props.pageSize)
                            }}
                        />
                    </div>
                {/* } */}
            </div>
        </div>
    )
}

PaginationScreen.defaultProps = {
    activePage: 1,
    pageSize: 20,
    currentCount: 0,
    totalCount: 0,
    pageRange: 10
};

export default PaginationScreen;
