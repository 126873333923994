import React from 'react';
import './Profile.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateProfile, changePassword, onDisplayError } from '../../actions/Login';
import swal from 'sweetalert';
import $ from 'jquery';

class Profile extends React.Component {

	constructor (props) {
		super(props);
		this.state = { admin_id : this.props.admin_info.id };
		this.onChangeHandler = this.onChangeHandler.bind(this);
	}
	
	onChangeHandler = (e) => {
		const { name, value } = e.target;
		let admin = this.props.admin_info;
		admin[name] = value;
		this.setState({ admin : admin });
	}

	onUpdateProfileSubmit = (e) => {
		e.preventDefault();

		swal({
			title: "Are you sure?",
			text: "To update your profile?",
			buttons: ['Cancel', 'Update'],
		})
		.then(willDelete => {
			if (willDelete) {
				const formData = new FormData(document.getElementById('update_profile_form'));
				formData.append('id', this.state.admin_id);
				formData.append('enabled', 1);
				this.props.onUpdateProfile(formData);
			}
		});
	}

	onChangePasswordSubmit = (e) => {
		e.preventDefault();

		if($('#oldPassword').val().length < 6)
		{
		  this.props.onDisplayErroMessage('Provide current password');
		}
		else if($('#new_password').val().length < 6)
		{
		  this.props.onDisplayErroMessage('Password must have atleast 6 characters');
		}
		else if($('#new_password').val() !== $('#confirm_password').val())
		{
		  this.props.onDisplayErroMessage('Confirm password must be same as password');
		}
		else
		{
		  swal({
			title: "Are you sure?",
			text: "To update new password?",
			buttons: ['Cancel', 'Update'],
		  })
		  .then(willDelete => {
			if (willDelete) {
			  var formData = new FormData(document.getElementById('change_password_form'));
			  formData.append('id', this.state.admin_id);

			  this.props.onChangePassword(formData);
			}
		  });
		}
	}

  	render() {

		const admin = this.props.admin_info;

    	return (
			<div className="row">
				<div className="col">
		
					{/* Breadcrumbs and add button container */}
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
							<nav area-label="breadcrumb">
								<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
								<li className="breadcrumb-item active" area-current="page">Profile</li>
								</ol>
							</nav>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
							<h4 className="text-center page_header"> {admin.firstName} </h4>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
						</div>
					</div>

					{/* Edit admin profile */}
					<div className="row edit_container form_container">
						<div className="col"></div>
						<div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 p-3">
							<div className="row">
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">

									<form className="card p-3" id="update_profile_form" onSubmit={this.onUpdateProfileSubmit} autoComplete="off">

										<h5 className="text-center page_header my-3 text-uppercase"> Edit Profile </h5>

										{/* <div className="row"> */}
											<div className="form-group">
												<label className="small_text"> First Name : </label>
												<input type="text" name="firstName" placeholder="First Name" value={admin.firstName ? admin.firstName : ''} onChange={this.onChangeHandler} className="form-control" required/>
											</div>

											{/* <div className="col">
												<label className="small_text"> Last Name : </label>
												<input type="text" name="lastName" placeholder="Last Name" value={admin.lastName ? admin.lastName : ''} onChange={this.onChangeHandler} className="form-control"/>
											</div> */}
										{/* </div> */}

										<div className="form-group">
											<label className="small_text"> Email ID : </label>
											<span className="form-control"> {admin.email ? admin.email : ''} </span>
											<input type="hidden" name="email" defaultValue={admin.email} />
										</div>

										<div className="form-group">
											<label className="small_text"> Mobile Number : </label>
											<input type="number" name="mobile" placeholder="Mobile Number" value={admin.mobile ? admin.mobile : ''} onChange={this.onChangeHandler} className="form-control" required/>
										</div>

										<div className="form-group text-center mt-4">
											<button type="submit" className="btn btn-success" disabled={this.props.isLoading} > Update Profile </button>
										</div>

									</form>

								</div>

								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">

									<form className="card p-3" id="change_password_form" onSubmit={this.onChangePasswordSubmit} autoComplete="off">

										<h5 className="text-center page_header my-3 text-uppercase"> Change Password </h5>

										<div className="form-group">
											<label className="small_text"> Current Password : </label>
											<input type="password" id="oldPassword" name="oldPassword" placeholder="Current Password" className="form-control" required/>
										</div>
										
										<div className="form-group">
											<label className="small_text"> New Password : </label>
											<input type="password" id="new_password" name="password" placeholder="New Password" className="form-control" required/>
										</div>

										<div className="form-group">
											<label className="small_text"> Confirm Password : </label>
											<input type="password" id="confirm_password" placeholder="Confirm Password" className="form-control" required/>
										</div>

										<div className="form-group text-center mt-2">
											<button type="submit" className="btn btn-success" disabled={this.props.isLoading} > Update Password </button>
										</div>

									</form>

								</div>
							</div>
						</div>
						<div className="col"></div>
					</div>

				</div>
			</div>
		);
  	}

}

const mapStatesToProps = (state) => {
	return {
		isLoading: state.admin.isLoading,
		admin_info: state.admin.admin_info
	}
}

const mapActionsToProps = {
	onUpdateProfile : updateProfile,
	onChangePassword : changePassword,
	onDisplayErroMessage : onDisplayError
}

export default connect(mapStatesToProps, mapActionsToProps) (Profile);
