import React from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import {Server, ApiPaths} from '../../../utils/Server';
import moment from 'moment';

class EditTaskGroup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {isLoading: false, error: '', groupTypes: [], groupData: {}, groupID: props.match.params.groupID};
  }

  componentDidMount() {
    this.handleGetGroups(this.state.groupID);
    this.handleGetGroupTypes();
  }

  handleGetGroups = (groupID) => {
    this.setState({isLoading: true}, async () => {
        await Server.get(ApiPaths.EDIT_TASK_GROUP + groupID)
          .then(response => {
              const { data } = response;
              if(data.statusCode === 200) {
                this.setState({groupData: data.data});
              } else {
                alert('Something went wrong', response.data.message);
              }
          })
          .catch(err => {
              alert('Something went wrong');
          });

          this.setState({isLoading: false});
    });
  }

  handleGetGroupTypes = () => {
    this.setState({isLoading: true}, async () => {
        await Server.get(ApiPaths.GET_TASK_GROUP_TYPES)
          .then(response => {
              const { data } = response;
              if(data.statusCode === 200) {
                this.setState({groupTypes: data.data});
              } else {
                alert('Something went wrong', response.data.message);
              }
          })
          .catch(err => {
              alert('Something went wrong');
          });

          this.setState({isLoading: false});
    });
  }

  handleValueChange = (e) => {
    const { name, value } = e.target;
    let groupData = this.state.groupData;
    groupData[name] = value;

    // Handle date change
    if(name === "dob" && value !== ' ' && value !== undefined) {
        var today = moment(new Date());
        var birthDate = moment(new Date(value));
        var diffYears = today.diff(birthDate, 'years');
        groupData['age'] = diffYears; // Update age
    }

    this.setState({ groupData : groupData});
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    const {groupID, groupData} = this.state;

    this.setState({error: ''});
    swal({
      title: "Are you sure?",
      text: "To update this task group?",
      buttons: ['CANCEL', 'UPDATE'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('add_group_form'));
        formData.append('id', groupID);
        formData.append('enabled', groupData.enabled);
        this.handleUpdateGroup(formData);
      }
    });
  }

  handleUpdateGroup = (formData) => {
    this.setState({isLoading: true}, async () => {
        await Server.post(ApiPaths.UPDATE_TASK_GROUP, formData)
          .then(response => {
              const { data } = response;
              if(data.statusCode === 200) {
                swal("Success", "Task group updated", {icon: 'success'});
              } else {
                this.setState({error: response.data.message});
              }
          })
          .catch(err => {
            this.setState({error: err.message});
          });

          this.setState({isLoading: false});
    });
  }

  render() {
    const {isLoading, error, groupData, groupTypes} = this.state;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/task-groups">Task Groups</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Group</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> EDIT GROUP </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              {/* <Link to="/task-groups/add-group"><button type="button" className="btn btn-primary btn-sm">ADD <i className="ml-1 fa fa-plus"></i></button></Link> */}
            </div>
          </div>
          
          {/* Edit new group form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-11 col-12 card p-3">
              <br/>

              <form id="add_group_form" onSubmit={this.handleFormSubmit} autoComplete="off">

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                        <label className="small_text">Group Name *</label>
                        <input type="text" name="groupName" placeholder="Group Name" className="form-control" value={groupData ? groupData.groupName : ''} onChange={(e) => this.handleValueChange(e)} required autoFocus/>
                    </div>

                    <div className="form-group">
                        <label className="small_text">Group Type *</label>
                        <select name="type" className="form-control" value={groupData ? groupData.type : ''} onChange={(e) => this.handleValueChange(e)} required>
                            <option value="">Select type</option>
                            {groupTypes.map((type) => (
                                <option key={type.id} value={type.id}>{type.type_name}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label className="small_text">Task Timer *</label>
                        <select name="taskTimer" className="form-control" value={groupData ? groupData.taskTimer : ''} onChange={(e) => this.handleValueChange(e)} required>
                            <option value="">Select On/Off</option>
                            <option value={true}>{'ON'}</option>
                            <option value={false}>{'OFF'}</option>
                        </select>
                    </div>

                    <div className="row form-group">
                        <div className="col">
                            <label className="small_text">Max Time (in Minutes) *</label>
                            <input type="number" min={0} max={1000} name="maxTaskTimer" placeholder="Max Time (in Minutes)" className="form-control" value={groupData ? groupData.maxTaskTimer : ''} onChange={(e) => this.handleValueChange(e)} required/>
                        </div>
                        <div className="col">
                            <label className="small_text">Max tasks per screen *</label>
                            <input type="number" min={0} max={1000} name="taskScreen" placeholder="Max tasks per screen" className="form-control" value={groupData ? groupData.taskScreen : ''} onChange={(e) => this.handleValueChange(e)} required/>
                        </div>
                    </div>

                    <div className="row form-group">
                        <div className="col">
                            <label className="small_text">Max Questions *</label>
                            <input type="number" min={0} max={1000} name="questionMax" placeholder="Max Questions" className="form-control" value={groupData ? groupData.questionMax : ''} onChange={(e) => this.handleValueChange(e)} required/>
                        </div>
                        <div className="col">
                            <label className="small_text">Day Type *</label>
                            <select name="dayType" className="form-control" value={groupData ? groupData.dayType : ''} onChange={(e) => this.handleValueChange(e)} required>
                                <option value="">Select Day</option>
                                <option value={1}>{'Day 1'}</option>
                                <option value={2}>{'Day 2'}</option>
                            </select>
                        </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                        <label className="small_text">Instructions</label>
                        <textarea rows={15} name="description" className="form-control" placeholder="Instructions" value={groupData ? groupData.description : ''} onChange={(e) => this.handleValueChange(e)}></textarea>
                    </div>
                  </div>
                </div>

                {(error && error !== '') && <p className="small_text text-danger">{error}</p>}

                <div className="form-group text-center mt-4">
                  <button type="submit" className="btn btn-success" disabled={isLoading}>{isLoading ? 'Updating...' : 'Update Group'}</button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

export default EditTaskGroup;
