import React from 'react';
import './Admin.css';
import { connect } from 'react-redux';
import { setCurrentUser, onAdminLogout, hideMessage } from '../../actions/Login';
import Routes from '../Routes/';
import $ from 'jquery';
import {SetAuthToken} from '../../utils/Server';

class Admin extends React.Component {
	
	constructor(props){
		super(props);
		this.onHandleLogout = this.onHandleLogout.bind(this);
	}

	componentWillMount () {

		SetAuthToken(localStorage.getItem('token'));

		if(localStorage.getItem('admin_info') && localStorage.getItem('token')) {
			this.props.onSetCurrentUser(JSON.parse(localStorage.getItem('admin_info')));
		}
		
		if(this.props.isAuthenticated === false) {
			this.props.history.push('/admin/login');
		}
	}

	onHandleLogout (e) {
		e.preventDefault();
		this.props.onLogoutClick();
	}

	render() {
	
		const { message, error, admin_info } = this.props;

		return (
			<div className="container-fluid">
				<div className="row" id="menu_container">
					<div className="col"></div>
					<div className="col text-center">
						<img src={process.env.PUBLIC_URL + '/logo.png'} className="header_lgoo" alt="Logo"/>
						{/* <span className="text-uppercase" style={{color : '#233f78', fontSize:'13px', fontWeight:'300'}}> Goo<span style={{color : '#f37421'}}>Campus</span> Abroad </span> */}
					</div>
					<div className="col">
						<button className="float-right btn btn-danger btn-sm" onClick={this.onHandleLogout}><b> LOGOUT <i className="fa fa-sign-out"></i> </b></button>
						<h6 className="mt-3 float-right" style={{color: '#233f78'}}> Hello { admin_info.fname}, </h6>
					</div>
				</div>
				<div className="row pt-5">
					<div className="col pt-3">

						{/* Error or message display container */}
						<div className="message_error_container">
							{
								message && message !== '' ?
									<span className="message_text"> <i className="fa fa-check-circle"></i> {message} </span>
								: ''
							}
							{
								error && error !== '' ? 
									<span className="error_text"> <i className="fa fa-exclamation-circle"></i> {error} </span>
								: ''
							}
						</div>


						{/* Including routing components */}
						<Routes />
						
					</div>
				</div>
			</div>
		);
	}

	componentDidUpdate () {

		if(this.props.isAuthenticated === false) {
			this.props.history.push('/admin/login');
		}

		// Clear forms input if success
		if(this.props.message && this.props.message.length > 2) {
			$('.add_container .form-control').val('');
		}

		if(this.props.error !== '' || this.props.message !== '') {
			setTimeout( function () { this.props.onHideMessage() }.bind(this), 2000);
		}
	}
	
}

const mapStatesToProps = state => {
	return {
		isLoading: state.admin.isLoading,
		message: state.admin.message,
		error: state.admin.error,
		isAuthenticated: state.admin.isAuthenticated,
		admin_info: state.admin.admin_info,
	}
};

const mapActionsToProps = {
	onLogoutClick : onAdminLogout,
	onSetCurrentUser : setCurrentUser,
	onHideMessage : hideMessage
};

export default connect(mapStatesToProps, mapActionsToProps) (Admin);