import React from 'react';
import './Users.css';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import {Server, ApiPaths} from '../../utils/Server';
import moment from 'moment';

class EditUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {isLoading: false, error: '', userData: {}, userID: props.match.params.user_id};
  }
  componentDidMount() {
    this.handleGetUsers(this.state.userID);
  }

  handleGetUsers = (userID) => {
    this.setState({isLoading: true}, async () => {
        await Server.get(ApiPaths.GET_STUDENTS)
          .then(response => {
              const { data } = response;
              if(data.statusCode === 200) {
                var usersData = data.data.filter(user => parseInt(user.id) === parseInt(userID));
                this.setState({userData: usersData[0]});
              } else {
                alert('Something went wrong', response.data.message);
              }
          })
          .catch(err => {
              alert('Something went wrong');
          });

          this.setState({isLoading: false});
    });
  }

  handleValueChange = (e) => {
    const { name, value } = e.target;
    let userData = this.state.userData;
    userData[name] = value;

    // Handle date change
    if(name === "dob" && value !== ' ' && value !== undefined) {
        var today = moment(new Date());
        var birthDate = moment(new Date(value));
        var diffYears = today.diff(birthDate, 'years');
        userData['age'] = diffYears; // Update age
    }

    this.setState({ userData : userData});
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    const {userID, userData} = this.state;

    this.setState({error: ''});
    swal({
      title: "Are you sure?",
      text: "To update this student?",
      buttons: ['CANCEL', 'UPDATE'],
    })
    .then(willDelete => {
      if (willDelete) {
        var formData = new FormData(document.getElementById('edit_user_form'));
        formData.append('createdBy', 'admin');
        formData.append('id', userID);
        formData.append('enabled', userData.enabled);
        formData.append('status', userData.status);
        this.handleUpdateUser(formData);
      }
    });
  }

  handleUpdateUser = (formData) => {
    this.setState({isLoading: true}, async () => {
        await Server.post(ApiPaths.UPDATE_STUDENT, formData)
          .then(response => {
              const { data } = response;
              if(data.statusCode === 200) {
                swal("Success", "Student updated", {icon: 'success'});
              } else {
                this.setState({error: response.data.message});
              }
          })
          .catch(err => {
            this.setState({error: err.message});
          });

          this.setState({isLoading: false});
    });
  }

  render() {
    const {isLoading, error, userData} = this.state;

    return (
      <div className="row">
        <div className="col">
          
          {/* Breadcrumbs and add button container */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <nav area-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                  <li className="breadcrumb-item"><Link to="/users">Students</Link> </li>
                  <li className="breadcrumb-item active" area-current="page">Edit Student</li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <h4 className="text-center page_header"> EDIT STUDENT </h4>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
              <Link to="/users/add-user"><button type="button" className="btn btn-primary btn-sm">ADD <i className="ml-1 fa fa-plus"></i></button></Link>
            </div>
          </div>
          
          {/* Edit new user form  */}
          <div className="row add_container form_container">
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-12 card p-3">
              <br/>

              <form id="edit_user_form" onSubmit={this.handleFormSubmit} autoComplete="off">

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text">First Name *</label>
                        <input type="text" name="firstName" placeholder="First Name *" className="form-control" value={userData ? userData.firstName : ''} onChange={(e) => this.handleValueChange(e)} required autoFocus/>
                      </div>

                      <div className="col">
                        <label className="small_text">Last Name *</label>
                        <input type="text" name="lastName" placeholder="Last Name *" className="form-control" value={userData ? userData.lastName : ''} onChange={(e) => this.handleValueChange(e)} required/>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Email ID *</label>
                      <input type="email" name="email" placeholder="Email ID *" className="form-control" value={userData ? userData.email : ''} onChange={(e) => this.handleValueChange(e)} required/>
                    </div>

                    <div className="form-group">
                      <label className="small_text">Mobile Number *</label>
                      <input type="number" minLength={10} maxLength={10} name="mobile" placeholder="Mobile Number *" className="form-control" value={userData ? userData.mobile : ''} onChange={(e) => this.handleValueChange(e)} required/>
                    </div>

                    <div className="row form-group">
                      <div className="col">
                          <label className="small_text">Date of birth</label>
                          <input type="date" name="dob" className="form-control" placeholder="Date of birth" min={'1995-01-01'} max={'2016-12-31'} value={userData ? userData.dob : '1995-01-01'} onChange={(e) => this.handleValueChange(e)} required />
                      </div>
                      <div className="col">
                          <label className="small_text">Age in years *</label>
                          <input type="text" disabled className="form-control" value={(userData && userData.age && userData.age !== '') ? (userData.age + ' years')  : '0'} />
                          <input type="hidden" name="age" value={userData ? userData.age : 0} />
                      </div>
                    </div>

                    <div className="form-group">
                        <label className="small_text">Institution *</label>
                        <input type="text" name="institution" className="form-control" placeholder="Institution" value={userData ? userData.institution : ''} onChange={(e) => this.handleValueChange(e)} required />
                    </div>

                    <div className="form-group">
                        <label className="small_text">Class *</label>
                        <input type="text" name="clazz" className="form-control" placeholder="Class" value={userData ? userData.clazz : ''} onChange={(e) => this.handleValueChange(e)} required />
                    </div>
                
                    <div className="form-group">
                        <label className="small_text">Father Name *</label>
                        <input type="text" name="fatherName" className="form-control" placeholder="Father Name" value={userData ? userData.fatherName : ''} onChange={(e) => this.handleValueChange(e)} required />
                    </div>
                
                    <div className="form-group">
                        <label className="small_text">Father Occupation *</label>
                        <input type="text" name="fatherOccupation" className="form-control" placeholder="Father Occupation" value={userData ? userData.fatherOccupation : ''} onChange={(e) => this.handleValueChange(e)} required />
                    </div>
                
                    <div className="form-group">
                        <label className="small_text">Mother Name *</label>
                        <input type="text" name="motherName" className="form-control" placeholder="Mother Name" value={userData ? userData.motherName : ''} onChange={(e) => this.handleValueChange(e)} required />
                    </div>
                
                    <div className="form-group">
                        <label className="small_text">Mother Occupation *</label>
                        <input type="text" name="motherOccupation" className="form-control" placeholder="Mother Occupation" value={userData ? userData.motherOccupation : ''} onChange={(e) => this.handleValueChange(e)} required />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                        <label className="small_text">Address 1</label>
                        <textarea rows={6} name="address1" className="form-control" placeholder="Address 1" value={userData ? userData.address1 : ''} onChange={(e) => this.handleValueChange(e)}></textarea>
                    </div>
                
                    <div className="form-group">
                        <label className="small_text">Address 2</label>
                        <textarea rows={6} name="address2" className="form-control" placeholder="Address 2" value={userData ? userData.address2 : ''} onChange={(e) => this.handleValueChange(e)}></textarea>
                    </div>
                
                    <div className="form-group">
                        <label className="small_text">Address 3</label>
                        <textarea rows={5} name="address3" className="form-control" placeholder="Address 3" value={userData ? userData.address3 : ''} onChange={(e) => this.handleValueChange(e)}></textarea>
                    </div>
                
                    <div className="row form-group">
                      <div className="col">
                        <label className="small_text">City</label>
                        <input type="text" name="city" className="form-control" placeholder="City" value={userData ? userData.city : ''} onChange={(e) => this.handleValueChange(e)} />
                      </div>
                      <div className="col">
                        <label className="small_text">Pin Code</label>
                        <input type="text" name="pinCode" className="form-control" placeholder="Pin Code" value={userData ? userData.pinCode : ''} onChange={(e) => this.handleValueChange(e)} />
                      </div>
                    </div>

                    <div className="form-group">
                        <label className="small_text">Secondary Email ID</label>
                        <input type="email" name="secEmail" className="form-control" placeholder="Secondary Email ID" value={userData ? userData.secEmail : ''} onChange={(e) => this.handleValueChange(e)} />
                    </div>
                    
                    <div className="form-group">
                        <label className="small_text">Secondary Mobile</label>
                        <input type="number" name="secMobile" className="form-control" placeholder="Secondary Mobile" value={userData ? userData.secMobile : ''} onChange={(e) => this.handleValueChange(e)} />
                    </div>
                  </div>
                </div>

                {(error && error !== '') && <p className="small_text text-danger">{error}</p>}

                <div className="form-group text-center mt-4">
                  <button type="submit" className="btn btn-success" disabled={isLoading}>{isLoading ? 'Updating...' : 'Update User'}</button>
                </div>

              </form>

            </div>
            <div className="col"></div>
          </div>

        </div>
      </div>
    );
  }
}

export default (EditUser);