import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Images from '../../assets/images';
import {Server, ApiPaths} from '../../utils/Server';
import swal from 'sweetalert';
import { SortItems } from '../../utils/DataTable';
import Pagination from '../../utils/Pagination';

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {isLoading: false, usersData: [], searchKey: '', sortKey: 1, pageNo: 1, pageSize: 50, currentCount: 0, totalCount: 0};
    }

    componentDidMount() {
        this.handleApiCall();
    }

    handleApiCall = () => {
        this.setState({isLoading: true}, () => {
            Server.get(ApiPaths.GET_STUDENTS)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.setState({usersData: data.data});
                    }
                })
                .catch(err => {
                    alert('Something went wrong');
                });

                this.setState({isLoading: false});
        });
    }

    handleUpdateStatus = (adminID, status) => {
      swal({
          title: "Are you sure?",
          text: "To update this admin status",
          buttons: ["CANCEL", "UPDATE"],
      })
      .then((willSubmit) => {
        if (willSubmit) {
          var formData = new FormData();
          formData.append('id', adminID);
          formData.append('enabled', status);

          this.setState({isLoading: true}, () => {
            Server.post(ApiPaths.UPDATE_STUDENT_STATUS, formData)
                .then(response => {
                    const { data } = response;
                    if(data.statusCode === 200) {
                        this.handleApiCall();
                    }
                })
                .catch(err => {
                    alert('Something went wrong');
                });

                this.setState({isLoading: false});
          });
        }
      });
    }

    handleSearchAndSort = (sortKey, searchKey) => {
      this.setState({sortKey: sortKey, searchKey: searchKey, pageNo: 1});
    }

    handlePageChange = (pageNo, pageSize) => {
      this.setState({pageNo: pageNo, pageSize: pageSize});
    }

    renderUsers = () => {
        const {sortKey, searchKey, pageNo, pageSize} = this.state;
        var usersData = this.state.usersData;
        // var totalCount = 0;
        
        if(this.state.usersData.length > 0) {
          // Sorting
          if (sortKey === 1) {
            SortItems(usersData, 'enabled', 2, 2);
          } else if (sortKey === 2) {
            SortItems(usersData, 'enabled', 2, 1);
          } else if (sortKey === 3) {
            SortItems(usersData, 'firstName', 1, 2);
          } else if (sortKey === 4) {
            SortItems(usersData, 'firstName', 1, 1);
          }

          // Searching
          if(searchKey !== '') {
            usersData = usersData.filter(user => 
              user.firstName.toLowerCase().includes(searchKey) || 
              user.lastName.toLowerCase().includes(searchKey) || 
              user.email.toLowerCase().includes(searchKey) || 
              user.mobile.toString().includes(searchKey))
              // user.institution.toLowerCase().includes(searchKey))
              // (user.age ? user.age.toString().includes(searchKey) : '') || 
              // (user.city ? user.city.toLowerCase().includes(searchKey) : '') || 
              // (user.clazz ? user.clazz.toString().includes(searchKey) : '') || 
              // (user.userStatus ? user.userStatus.toLowerCase().includes(searchKey) : '')
          }

          // Update total count
          // totalCount = usersData.length;

          // Pagination
          // usersData = usersData.slice(((pageNo - 1) * pageSize), ((pageNo) * pageSize));
        }

        if(usersData && usersData.length > 0) {
          return (
            <tbody>
                {usersData.map((user, index) => (
                    <tr key={index}>
                      {/* <input type="text" className="totalCount" value={totalCount}/>
                      <input type="text" className="currentCount" value={usersData.length}/> */}
                        <td style={{verticalAlign: 'center'}}>{index+1}</td>
                        <td>{user.firstName + ' ' + user.lastName}</td>
                        <td>{user.email}</td>
                        <td>{user.mobile}</td>
                        <td>{user.age}</td>
                        <td>{user.clazz}</td>
                        <td>{user.institution}</td>
                        <td>{user.city}</td>
                        <td>{user.userStatus}</td>
                        <td>{user.enabled === 1 ? <button className="viewBtn bg-success" title="Active user" onClick={() => this.handleUpdateStatus(user.id, 0)}>ACTIVE </button> : <button className="viewBtn bg-danger" title="Inactive user" onClick={() => this.handleUpdateStatus(user.id, 1)}>INACTIVE </button>}</td>
                        <td><Link to={'/users/edit-user/' + user.id}><button className="icon_button" title="Edit"> <i className="fa fa-edit text-primary"></i> </button></Link></td>
                    </tr>
                ))}
            </tbody>
          )
        } else {
          return (
            <tbody>
                <tr>
                    <td colSpan="12" className="text-center"> No students found.</td>
                </tr>
            </tbody>
          )
        }
    }

    render() {
        const {isLoading, sortKey, searchKey, pageNo, pageSize, currentCount, totalCount, usersData} = this.state;

        return (
          <div className="row">
              <div className="col">
              
              {/* Breadcrumbs and add button container */}
              <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <nav area-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/">Dashboard</Link> </li>
                          <li className="breadcrumb-item active" area-current="page">Students</li>
                        </ol>
                    </nav>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <h4 className="text-center page_header"> STUDENTS </h4>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                    <Link to="/users/add-user"><button type="button" className="btn btn-primary btn-sm">ADD <i className="ml-1 fa fa-plus"></i></button></Link>
                  </div>
              </div>

              {/* filters, sort and search container */}
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <div className="form-group d-inline">
                    <label className="small_text">Sort by : </label>
                    <select className="form-control ml-2 w-auto d-inline" value={sortKey} onChange={(e) => this.handleSearchAndSort(parseInt(e.target.value), searchKey)}>
                        <option value="1">Active First</option>
                        <option value="2">Inactive First</option>
                        <option value="3">Names A-Z</option>
                        <option value="4">Names Z-A</option>
                    </select>
                    </div>
                </div>

                <div className="col" />

                <div className="col-md-6 col-12 text-right">
                    <div className="input-group mb-3">
                      <div className="input-group-append">
                          <button className="search_btn btn-primary px-3" type="button"><i className="fa fa-search"></i></button> 
                      </div>
                      <input type="search" name="search_key" className="filter_key form-control" placeholder="Search by name, email or mobile number" value={searchKey} onChange={(e) => this.handleSearchAndSort(sortKey, e.target.value.toString().toLowerCase())} autoComplete="off"/>
                    </div>
                </div>
              </div>

              {/* Display all users table */}
              <table className="table table-bordered table-striped">
                  <thead w="100%">
                      <tr>
                          <th width="5%">Sl.No.</th>
                          <th width="35%">Full Name</th>
                          <th width="15%">Email ID</th>
                          <th width="15%">Mobile</th>
                          <th width="5%">Age</th>
                          <th width="10%">Class</th>
                          <th width="20%">Institution</th>
                          <th width="10%">City</th>
                          <th width="10%">Status</th>
                          <th width="10%">Active Status</th>
                          <th width="5%">Edit</th>
                      </tr>
                  </thead>

                  {/* Display all the courses */}
                  {this.renderUsers()}
              </table>
              
              {/* Pagination container */}
              {/* <Pagination activePage={pageNo} pageSize={pageSize} currentCount={parseInt(document.getElementsByClassName('currentCount').value)} totalCount={parseInt(document.getElementsByClassName('totalCount').value)} handlePageChange={(pageNo, pageSize) => this.handlePageChange(pageNo, pageSize)} /> */}

              {isLoading && 
                <div className="mainLoaderContainer">
                  <img src={Images.loader} height={50} alt="Loading . . ."/>
                </div>
              }

              </div>
          </div>
        )
    }
}

export default Users;