import React from 'react';

class Fixit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selectedImage: 0};
    }

    renderAnswerView = (answer, index) => {
        var answerSaved = JSON.parse(answer.response1); // Set default image positions
        var subQuestions = [];

        if(answer.task_option1 !== '') {
            subQuestions = answer.task_option1.split('||');
        }
    
        return (
            <div key={index} className="card mt-3">
                <div className="card-body row">
                    <div className="col-md-10 fixitImageDropView">
                        <h6 className="font-weight-bold">Question {index+1}</h6>
                        <h6 className="font-weight-bold">Time taken : {answer.time_taken ? answer.time_taken : 0} seconds</h6>
                        <img src={answer.task_description} className="img-fluid" style={{height: 200}} alt="" />

                        <div>
                            {answerSaved.map((subQuestion, index) => (
                                (subQuestion.imgHeight && subQuestion.imgWidth) && (
                                    <div key={index} className="m-4 draggableView" style={subQuestion}>
                                        <img src={subQuestions[index]} style={{height: subQuestion.imgHeight, width: subQuestion.imgWidth}} alt="" />
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                </div>
                <hr/>
            </div>
        )
    }
      
    render() {
        const {taskAnswers} = this.props;

        return (
            <div className="mt-2 mb-4 pb-2">
                {taskAnswers && taskAnswers.map((answer, index) => (
                    this.renderAnswerView(answer, index)
                ))}
            </div>
        );
    }
}

export default Fixit;
