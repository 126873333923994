import React from 'react';

class HandWriting extends React.Component {

    renderAnswerView = (answer, index) => {
        var imagePaths = [];
        if(answer.response1 !== '') {
            imagePaths = JSON.parse(answer.response1);
        }

        return (
            <div key={index} className="card mt-3">
                <div className="card-body row">
                    <div className="col">
                        <h6 className="font-weight-bold">{'Q. ' + (index+1)}</h6>
                        <h6 className="font-weight-bold">Time taken : {answer.time_taken ? answer.time_taken : 0} seconds</h6>

                        <div className="row">
                            {imagePaths.map((imgPath, index) => (
                                <div key={index} className="col p-3">
                                    <a href={imgPath} target="_blank" rel="noopener noreferrer">
                                        <img src={imgPath} className="img-fluid mt-3" alt=""/>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    };
      
    render() {
        const {taskAnswers} = this.props;

        return (
            <div className="mt-2 mb-4 pb-2">
                {taskAnswers && taskAnswers.map((answer, index) => (
                    this.renderAnswerView(answer, index)
                ))}
            </div>
        );
    }
}

export default HandWriting;