import React from 'react';
import './Login.css';
import { connect } from 'react-redux';
import { onAdminLogin, hideMessage } from '../../actions/Login';

class Login extends React.Component {
	constructor (props) {
		super(props);
		// this.state = { email : 'admin@goocampus.in', password : 'admin123', error : '' };
		this.state = { email : '', password : '', error : '' };
		this.onChange = this.onChange.bind(this);
		this.onHandleSubmit = this.onHandleSubmit.bind(this);
	}

	onChange (e) {
		this.setState({ [e.target.name]: e.target.value });	
	}

	onHandleSubmit (e) {
		e.preventDefault();

		if(this.state.email === '' || this.state.password === ''){
			this.setState({error : 'Email and Password required.'});
		}
		else if(this.state.password.length < 6 ){
			this.setState({error : 'Password must have atleast 6 characters.'});
		}
		else if(this.state.email !== '' && this.state.password.length >= 6)
		{
			this.setState({error : ''});
			var formData = new FormData(document.getElementById('login_form'));
			this.props.onLoginClick(formData);
		}
	}

	render() {

		let res_message = '';

		if(this.props.message !== '') {
			res_message = <span className="small_text text-success"> { this.props.message} </span>;
		}
		else if(this.props.error !== '') {
			res_message = <span className="small_text text-danger"> {this.props.error} </span>;
		}
		
		if(this.state.error !== '') {
			res_message = <p className="small_text text-danger"> {this.state.error} </p>
		}

		return (

			<div className="row">
				<div className="col"></div>
				<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-5 pt-3">
					<div className="text-center">
						<img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" height="66"/>
						{/* <h4 className="mt-2 text-uppercase" style={{color:'#233f78'}}><b> Goo<span style={{color:'#f37421'}}>Campus</span> Abroad </b></h4> */}
					</div>

					<div className="mt-4" id="login_container">
						
						<h3 className="text-center page_header"> Admin Login </h3>

						<form id="login_form" name="login_form" onSubmit={this.onHandleSubmit} autoComplete="off">
							<div className="form-group">
								<label className="small_label"> Email ID *  </label>
								<input type="email" name="email" className="form-control" placeholder="Email ID" onChange={this.onChange} value={this.state.email} autoFocus/>
							</div>
							<div className="form-group">
								<label className="small_label"> Password * </label>
								<input type="password" name="password" className="form-control" placeholder="Passsword" onChange={this.onChange} value={this.state.password}/>
							</div>

							<div className="text-center"> {res_message} </div>

							<div className="form-group text-center">
								<button className="btn btn-primary btn-lg" type="submit" disabled={this.props.isLoading}> LOGIN </button>
							</div>
						</form>
					</div>
				</div>
				<div className="col"></div>
			</div>
		);
	}

	componentDidUpdate() {
		if(this.props.error !== '' || this.props.message !== '') {
			setTimeout( function () { this.props.onHideMessage() }.bind(this), 3000);
		}

		if(this.state.error !== '') {
			setTimeout( function () { this.setState({ error : ''}) }.bind(this), 3000);
		}
	}

}

const mapStatesToProps = state => {
	return {
		admin_info: state.admin.admin_info,
		message: state.admin.message,
		error: state.admin.error,
		isLoading: state.admin.isLoading,
		isAuth: state.admin.isAuth
	}
};

const mapActionsToProps = {
	onLoginClick : onAdminLogin,
	onHideMessage : hideMessage
};

export default connect(mapStatesToProps, mapActionsToProps) (Login);
