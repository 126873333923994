import React from 'react';
import Images from '../../assets/images';

class Enact extends React.Component {

    renderAnswerView = (answer, index) => {
        var answerImage = '';
        if(answer.response1 !== '') {
            answerImage = JSON.parse(answer.response1);
        }

        return (
            <div key={index} className="card mt-3">
                <div className="card-body row">
                    <div className="col p-4 text-center">
                        <h6 className="font-weight-bold">Question {index+1}</h6>
                        <img src={answer.task_description} className="img-fluid" alt="" />
                    </div>
                    <div className="col answerCard text-center">
                        <h6 className="font-weight-bold">Answer</h6>
                        <img src={answerImage} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        )
    }
      
    render() {
        const {taskAnswers} = this.props;

        return (
            <div className="mt-2 mb-4 pb-2">
                {taskAnswers && taskAnswers.map((answer, index) => (
                    this.renderAnswerView(answer, index)
                ))}
            </div>
        );
    }
}

export default Enact;