import React from 'react';

class SecretWords extends React.Component {

    renderAttemptsWords = (totalAttempts, savedAnswer, questionWords) => {
        return (
            <div>
                {totalAttempts.map((attempt, atteIndex) => (
                    <div key={atteIndex} style={{height: 55}}>
                        {questionWords.map((answer, index) => (
                            this.renderAttempt(atteIndex, questionWords, savedAnswer, index)
                        ))}
                    </div>
                ))}
            </div>
        )
    }

    renderAttempt = (atteIndex, questionWords, savedAnswer, index) => {
        const attemptIndex = ((atteIndex * questionWords.length) + (index));
        const attemptAnswer = savedAnswer[attemptIndex];
        const wordBackColor = attemptAnswer && (attemptAnswer.rightPlace ? '#05ea1f' : attemptAnswer.wrongPlace ? 'yellow' : 'grey');
        const wordColor = attemptAnswer && (attemptAnswer.rightPlace ? 'black' : attemptAnswer.wrongPlace ? 'black' : 'white');

        if(savedAnswer.length > attemptIndex) {
            return <span key={index} style={{height: 60, width: 60, border: '1px solid black', padding: '15px 25px', backgroundColor: wordBackColor, color: wordColor}}><b>{attemptAnswer.guessedWord}</b></span>
        } else {
            return <span key={index} style={{height: 60, width: 60, border: '1px solid black', padding: '15px 30px'}}><b>{' '}</b></span>
        }
    }

    renderAnswerView = (answer, index) => {
        var answerData = '';
        var questionWords = [];
        var totalAttempts = [1,2,3,4,5,6,7];
        var attempts = 0;
        var questionTitle = answer.task_description.replace(/[^a-zA-Z ]/g, ""); // Remove special characters to get word

        if(answer.task_description !== '') {
            questionWords = answer.task_description.split('||');
        }

        if(answer.response1 !== '') {
            answerData = JSON.parse(answer.response1);
        }

        if(questionWords.length > 0) {
            attempts = Math.ceil(answerData.length / questionWords.length);
        }

        if(questionWords.length === 3) {
            totalAttempts.length = 5;
        }

        // alert(answer.time_taken);

        return (
            <div key={index} className="col-md-6 col-xs-12 p-2">
                <div className="card p-3">
                    <h6 className="font-weight-bold">Q {index+1}. <br/> {'Word : ' + questionTitle}</h6>
                    <hr className="my-2" />
                    <h6 className="font-weight-bold mb-1">Guessed letters : {answerData.length}</h6>
                    <h6 className="font-weight-bold mb-1">Time taken : {answer.time_taken ? answer.time_taken : 0} seconds</h6>
                    <h6 className="font-weight-bold mb-1">Attempts : {attempts}</h6>
                    <div className="row mt-4 mb-2 text-center">
                        <div className="col">
                            {this.renderAttemptsWords(totalAttempts, answerData, questionWords)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
      
    render() {
        const {taskAnswers} = this.props;

        return (
            <div className="row mb-4 pb-2">
                {taskAnswers && taskAnswers.map((answer, index) => (
                    this.renderAnswerView(answer, index)
                ))}
            </div>
        );
    }
}

export default SecretWords;